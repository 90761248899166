import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ButtonBox from '../components/ButtonBox';
import { AreYouSureModal, EditJobStatusModal } from '../redux/reduxStats/sliceModal';
import { get, post } from '../APIs/api';
import { RemoveMember, UpdateExistJobStatus } from '../redux/reduxStats/sliceDataManagement';
import DropDownBox from '../components/DropDownBox';

const ModalEditJobStatus = () => {
  let modalPopUp = useSelector(state=>state.modalReducer.customModal.editJobStatus);
  let selectedData = useSelector(state=>state.modalReducer.selectedData);

  const [jobDetails, setJobDetails] = useState(selectedData);
  const [jobStatus, setJobStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatchItem = useDispatch();

  useEffect(()=>{
    setJobDetails(selectedData)
    loadJobStatus();
  },[selectedData])

  const loadJobStatus = async () => {
    try{
      let jobStatusData = await get(`jobs/status`);
      if(jobStatusData.response != null){
        const filteredData = jobStatusData.response.filter(item => item.name !== "Pending");
          setJobStatus(filteredData);
          // setJobDetails(prevState => ({
          //   ...prevState,
          //   jobStatusValue: "",
          // }))
      }
    }catch(error){
      setJobStatus(null);
    }
  }

  const ClosePopUp = () => {
    setIsUpdated(false);
    dispatchItem(
      EditJobStatusModal(false,null)
    )
  }

  const handleDropChange = (event,valueName, typeName) => {
    const { options, selectedIndex } = event.target;
    const name = options[selectedIndex].text;
    const value = options[selectedIndex].value;
    // Now calling onChange prop with both selected value and name
    setJobDetails(prevState => ({
      ...prevState,
      [valueName]: value,
      [typeName]: name,
    }))
  };

  const UpdateJobStatus = async () => {
    if(jobDetails.jobStatusValue == "" || jobDetails.jobStatusValue == null){
      return;
    }

    setIsLoading(true);
    try{
      let existJobData = await post(`jobs/update`,jobDetails);
      if(existJobData.response != null){
        setIsUpdated(true);
        dispatchItem(
          UpdateExistJobStatus(selectedData.jobStatus,jobDetails)
        )
        alert(`Congratz! ${existJobData.message}`);
      }else{
        alert(`Error: ${existJobData.message}`)
      }
    }catch(error){
      console.log(error);
      alert(`Something went wrong...`);
    }
    setIsLoading(false);
  }

  return (
    <>
    <div
      className={`
        fixed inset-0 flex justify-center items-center transition-colors
        ${modalPopUp ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`
           rounded-xl p-6 transition-all
          ${modalPopUp ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
      {!isUpdated ?
      <div className="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-gray-800">
          <svg onClick={() => ClosePopUp()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 ml-auto hover:text-white text-gray-700" viewBox="0 0 1792 1792">
                  <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                  </path>
              </svg>
          <div className="w-full h-full text-center">
              
              <div className="flex flex-col justify-between h-full">
                  <p className=" text-xl font-bold text-gray-800 dark:text-gray-200">
                      JOB
                  </p>
                  <p className="mt-1 text-3xl font-bold text-gray-800 dark:text-gray-200">
                      {jobDetails?.jobType}
                  </p>
                  <p className="px-6 py-2 my-4 text-xl text-gray-600 dark:text-gray-400">
                      What's the job status?
                  </p>
                  
                  <DropDownBox 
                    dropDownName={"Job Status"}
                    dropDownData={jobStatus}
                    onChange = {(e) => handleDropChange(e,"jobStatusValue","jobStatus")}
                    value={jobDetails?.jobStatusValue}
                    idField='jSID'
                  />
                  <div className="flex items-center justify-between w-full gap-4 mt-2">
                    <ButtonBox
                      name='Update'
                      isLoading={isLoading}
                      onClick={() => UpdateJobStatus()}
                    />
                  </div>
              </div>
          </div>
      </div>
      :
      <div className="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-gray-800">
          <div className="w-full h-full text-center">
              <div className="flex flex-col justify-between h-full">
                  <p className="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                      Job {jobDetails.jobStatus} Successfully
                  </p>
                  <p className="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                      {jobDetails.jobType}
                  </p>
                  <div className="flex items-center justify-between w-full gap-4 mt-8">
                    <ButtonBox
                      name='Close'
                      onClick={() => ClosePopUp()}
                    />
                  </div>
              </div>
          </div>
      </div>
      }
      </div>
    </div>
    </>
  )
}

export default ModalEditJobStatus
