import React, { useState } from 'react'
import InputBox from './InputBox'
import ButtonBox from './ButtonBox'
import ButtonBoxWithoutLoader from './ButtonBoxWithoutLoader'
import { post } from '../APIs/api'

const ProfileInfo = ({userProfile}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState(null);

    const initialState = {
        currentPassword: '',
        password: '',
        confirmPassword: '',
        email: userProfile.email
    }
    const [profileData, setProfileData] = useState(initialState);

          // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };


    const updatePassword = async () => {
        if(profileData.password == "" || profileData.confirmPassword == "" || profileData.password == ""){
            setErrors("Can't update the password.");
            return;
        }
        if(profileData.password !== profileData.confirmPassword){
            setErrors("Password not matched.");
            return;
        }
        setErrors(null);

        setIsLoading(true);
        try{
        const userData = await post('account/password',profileData);

        if(userData.response != null){
            alert(userData.message);
        }else{
            setErrors(userData.message);
        }
        }catch(error){
            setErrors("Something went wrong.");
        }
        setIsLoading(false);
        setProfileData(initialState);
    }


  return (
    <>
        <div className="max-w-2xl w-full overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    PROFILE
                </h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    Details and informations.
                </p>
            </div>
            <div className="border-t border-gray-200">
                <dl>
                    <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Full name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userProfile.firstName + " " + userProfile.lastName}
                        </dd>
                        
                        
                    </div>
                    <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            WorkForce Type
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userProfile.role}
                        </dd>
                    </div>
                    <div className="px-4 py-5 bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userProfile.email}
                        </dd>
                    </div>
                    <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Phone Number
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {userProfile.phoneNumber}
                        </dd>
                    </div>
                    {userProfile.role == "Technician" ? null :
                    <div className="px-4 py-5 bg-white sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Salary/Sales
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            PKR {userProfile.salary == null ? "-" : userProfile.salary}
                        </dd>
                    </div>
                    }
                </dl>
            </div>

            <hr/>
            <div className="px-4 pt-5 pb-1 sm:px-6">
            
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    SECURITY
                </h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    Keep your sensitive information to yourself.
                </p>
                {errors == null ? <p className='text-white '>-</p> : <p className= ' text-purple-900 dark:text-red-500'><b>{errors}</b></p> }
            </div>
            <div className="border-t border-gray-200 dark:bg-gray-800 bg-purple-800 flex items-center px-5">
                <p className='text-white mb-4 w-1/4'><b>Security</b></p>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"New Password"} 
                    name={"password"}
                    label={"New Password"} 
                    type={"password"}
                    onChange={handleChange}
                    value={profileData.password}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Confirm Password"} 
                    name={"confirmPassword"}
                    label={"Confirm Password"} 
                    type={"password"}
                    onChange={handleChange}
                    value={profileData.confirmPassword}
                  />
                </div>
            </div>
            <div className=" bg-purple-800 dark:bg-gray-800 flex items-center px-5">
                <p className='text-white mb-4 w-1/4'><b>Old Password</b></p>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"Current Password"} 
                    name={"currentPassword"}
                    label={"Current Password"} 
                    type={"password"}
                    onChange={handleChange}
                    value={profileData.currentPassword}
                  />
                  <div className='mx-2'></div>
                </div>
            </div>
            <div className=" bg-purple-800 dark:bg-gray-800 flex items-center p-5">
                <div className='w-full'></div>
                <ButtonBoxWithoutLoader
                    name='Update'
                    loadingText='Saving'
                    isLoading={isLoading}
                    onClick={() => updatePassword()}
                 />
            </div>
        </div>

        
    </>
  )
}

export default ProfileInfo
