import React from 'react'

const DatePickerBox = ({ name, value, onChange, label, type = "date", disableFutureDate, enableFullDate = false}) => {
  const today = new Date().toISOString().split('T')[0];
  let todayDate = new Date();
  let maxDate = new Date(todayDate);
  maxDate.setDate(maxDate.getDate() + 365);
  let maxDateStr = maxDate.toISOString().split('T')[0];
  return (
    <>
    {enableFullDate ? 
    <div className="mb-6 w-full items-star text-left">
    <label className='text-gray-100 dark:text-white text-sm pl-2'>{label}</label>
      <input
        type = {type}
        value = {value}
        name = {name}
        onChange={onChange}
        className="w-full bg-white rounded-md border border-stroke bg-transparent px-3 py-3 text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-gray"
      />
  </div>
    :
        <div className="mb-6 w-full items-star text-left">
          <label className='text-gray-100 dark:text-white text-sm pl-2'>{label}</label>
          {
          disableFutureDate ? 
            <input
              type = {type}
              value = {value}
              name = {name}
              onChange={onChange}
              max={today}
              className="w-full bg-white rounded-md border border-stroke bg-transparent px-3 py-3 text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-gray"
            />
          :
            <input
              type = {type}
              value = {value}
              name = {name}
              onChange={onChange}
              min={today}
              max={maxDateStr}
              className="w-full bg-white rounded-md border border-stroke bg-transparent px-3 py-3 text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-gray"
            />
          }
        </div>
    }
        </>
      );
}

export default DatePickerBox
