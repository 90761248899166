import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { get } from '../APIs/api';

const DropDownBox = ({dropDownName, onChange, value = null, dropDownData = null, idField = ""}) => {
  return (
    <>
<div className=" w-full mb-4 ">
<label className='text-gray-100 dark:text-white text-sm'>{dropDownName}</label>
    <select onChange={onChange} value={value} className="block px-3 py-1 bg-white border border-gray-300 rounded-md shadow-sm w-52 focus:outline-none focus:ring-primary-500 focus:border-primary-500" name="animals">
        <option value="">
            Select an option
        </option>
        {dropDownData?.map((item, index) => (
        <option key={index} value={item[idField]}>
            {item?.name}
        </option>
        ))}
    </select>
</div>

    </>
  )
}

export default DropDownBox
