import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ButtonBox from '../components/ButtonBox';
import { EditJobDetailModal } from '../redux/reduxStats/sliceModal';
import { get, post } from '../APIs/api';
import InputBox from '../components/InputBox';
import DatePickerBox from '../components/DatePickerBox';
import DropDownBox from '../components/DropDownBox';
import ButtonBoxWithoutLoader from '../components/ButtonBoxWithoutLoader';
import * as Yup from 'yup';
import TextAreaBox from '../components/TextAreaBox';
import { UpdateExistJob } from '../redux/reduxStats/sliceDataManagement';

const ModalEditJobDetails = () => {
  let modalPopUp = useSelector(state=>state.modalReducer.customModal.editJobDetails);
  let selectedData = useSelector(state=>state.modalReducer.selectedData);

  let userProfile = useSelector(state=>state.authReducer.userProfile);

  const [cities, setCities] = useState([]);
  const [jobDetails, setJobDetails] = useState(selectedData);
  const [password, setPassword] = useState("");
  const [jobTypes, setJobTypes] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);
  const [agents, setAgents] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(()=>{
    setJobDetails(selectedData)
    loadDropDown();
    loadJobStatus();
    loadCities();
    loadAgentList();
  },[selectedData])

  const [isLoading, setIsLoading] = useState(false);

  const dispatchItem = useDispatch();
  
  const loadAgentList = async () => {
    try{
      let agentListData = await get(`account/agents`);
      if(agentListData.response != null){
          setAgents(agentListData.response);
      }
    }catch(error){
      setAgents([]);
    }
  }

  const loadDropDown = async () => {
    try{
      let jobTypesData = await get(`jobs/types`);
      if(jobTypesData.response != null){
          setJobTypes(jobTypesData.response);
      }
    }catch(error){
      setJobTypes(null);
    }
  }

  const loadJobStatus = async () => {
    try{
      let jobStatusData = await get(`jobs/status`);
      if(jobStatusData.response != null){
          setJobStatus(jobStatusData.response);
      }
    }catch(error){
      setJobStatus(null);
    }
  }

  const loadCities = async () => {
    try{
      let citesData = await get(`panel/cities`);
      if(citesData.response != null){
          setCities(citesData.response);
      }
    }catch(error){
      setCities(null);
    }
  }

  const ClosePopUp = () => {
    dispatchItem(
      EditJobDetailModal(false, null)
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    if(name == "password"){
      setPassword(e.target.value)
    }
  };

  const handleDropChange = (event,valueName, typeName) => {
    const { options, selectedIndex } = event.target;
    const name = options[selectedIndex].text;
    const value = options[selectedIndex].value;
    // Now calling onChange prop with both selected value and name
    setJobDetails(prevState => ({
      ...prevState,
      [valueName]: value,
      [typeName]: name,
    }))
  };

  const validationSchemaForMember = Yup.object({
    customerName: Yup.string()
      .required('Customer name is required')
      .min(2, 'Customer name must be at least 2 characters long')
      .max(50, 'Customer name must not exceed 50 characters'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, 'Phone number must be at least 10 digits long')
      .max(15, 'Phone number must not exceed 15 digits'),
    address: Yup.string()
      .required('Address is required')
      .min(10, 'Address must be at least 10 characters long'),
    jobType: Yup.string()
      .required('Job type is required'),
    jobTypeValue: Yup.string()
      .required('Job type is required'),
    jobStatusValue: Yup.string()
      .required('Job Status is required'),
    jobCost: Yup.number()
      .typeError('Please enter job cost')
      .required('Job cost is required')
      .min(0, 'Job cost cannot be negative'),
    qty: Yup.number()
      .typeError('Please enter quantity')
      .required('Quantity is required')
      .min(1, 'Quantity cannot be negative'),
    cityValue: Yup.string()
      .required('City is required'),
    agent: Yup.mixed()
      .nullable(true) // Assuming 'agent' can be null and is not required
      .notRequired(),
    customerComments: Yup.string()
      .max(500, 'Comments must not exceed 500 characters')
      .notRequired(),
    bookingDate: Yup.date()
      .typeError('Please provide a valid booking date.') // Custom message for type error
      .required('Booking date is required')
      .min(new Date(), 'Booking date cannot be in the past'),
    bookingTime: Yup.string()
      .typeError('Please provide a valid time.') // Custom message for type error
      .required('Booking time is required'),
  });

  const validationSchemaForAdmin = Yup.object({
    customerName: Yup.string()
      .required('Customer name is required')
      .min(2, 'Customer name must be at least 2 characters long')
      .max(50, 'Customer name must not exceed 50 characters'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, 'Phone number must be at least 10 digits long')
      .max(15, 'Phone number must not exceed 15 digits'),
    address: Yup.string()
      .required('Address is required')
      .min(10, 'Address must be at least 10 characters long'),
    jobType: Yup.string()
      .required('Job type is required'),
    jobTypeValue: Yup.string()
      .required('Job type is required'),
    jobStatusValue: Yup.string()
      .required('Job Status is required'),
    jobCost: Yup.number()
      .typeError('Please enter job cost')
      .required('Job cost is required')
      .min(0, 'Job cost cannot be negative'),
    qty: Yup.number()
      .typeError('Please enter quantity')
      .required('Quantity is required')
      .min(1, 'Quantity cannot be negative'),
    cityValue: Yup.string()
      .required('City is required'),
    agent: Yup.mixed()
      .nullable(true) // Assuming 'agent' can be null and is not required
      .notRequired(),
    customerComments: Yup.string()
      .max(500, 'Comments must not exceed 500 characters')
      .notRequired(),
    bookingDate: Yup.date()
      .typeError('Please provide a valid booking date.') // Custom message for type error
      .required('Booking date is required'),
    bookingTime: Yup.string()
      .typeError('Please provide a valid time.') // Custom message for type error
      .required('Booking time is required'),
  });


  const PassValidation = async () => {
    try{
      if(userProfile.role == "Admin" || userProfile.role == "console"){
        await validationSchemaForAdmin.validate(jobDetails, { abortEarly: true });
      }else{
        await validationSchemaForMember.validate(jobDetails, { abortEarly: true });
      }
    }catch(err){
      if (err.name === 'ValidationError') {
        setErrors(err.errors[0]);
      } else {
        console.error('Error during login:', err);
      }
      return;
    }

    updateJob();
  }

  const updateJob = async () => {
    setErrors(null);
    setIsLoading(true);
    
    if(jobDetails.agent == "Select an option"){
      jobDetails.agent = "";
      jobDetails.agentValue = null;
    }
    
    try{
      let existJobData = await post(`jobs/update`,jobDetails);
      if(existJobData.response != null){
        dispatchItem(
          UpdateExistJob(jobDetails)
        )
        alert(`Congratz! ${existJobData.message}`);
      }else{
        alert(`Error: ${existJobData.message}`)
      }
    }catch(error){
      console.log(error);
      alert(`Something went wrong...`);
    }

    setIsLoading(false);
  }

  return (
    <>
    <div
      className={`
        fixed inset-0 flex justify-center items-center transition-colors
        ${modalPopUp ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`
           rounded-xl p-6 transition-all
          ${modalPopUp ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        <section className="w-full bg-purple-800 dark:bg-slate-600">
          
          <div className="p-4 bg-purple-900 dark:bg-gray-800 ">
              <div className="w-full flex">
                  <div className="inline-flex items-center justify-center space-x-4 w-full">
                    <p className=' text-3xl text-white'><b>Update Job Details</b></p>
                  </div>
                  <svg onClick={() => ClosePopUp()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 ml-auto hover:text-white text-gray-700" viewBox="0 0 1792 1792">
                    <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                    </path>
                  </svg>
                  
              </div>
          </div>

          <div className="space-y-2">
            <div className='py-2 px-8 w-full items-center'>
                <div className='flex justify-between items-center'>
                  <p className='text-white'><b>JOB: {jobDetails?.jobType} | {jobDetails?.customerName}</b></p>
                  <hr className='bg-purple-100'/>
                </div>
                {selectedData?.bookedByName != null ?
                  <div class="">
                  <p className='text-sm text-white'>Booked By: {selectedData?.bookedByName}</p>
                  </div>
                  :null}
              <hr/>
              <div className=' flex justify-around'>
                  <InputBox 
                    placeholder={"Customer Name"} 
                    name={"customerName"}
                    label={"Customer Name"} 
                    type={"text"}
                    onChange={handleChange}
                    value={jobDetails?.customerName}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Phone Number"} 
                    name={"phoneNumber"}
                    label={"Phone Number"} 
                    type={"text"}
                    onChange={handleChange}
                    value={jobDetails?.phoneNumber}
                  />
            </div>
            <div className=' flex justify-around'>
                  <TextAreaBox 
                    placeholder={"Customer Address"} 
                    name={"address"}
                    label={"Customer Address"} 
                    type={"textarea"}
                    onChange={handleChange}
                    value={jobDetails?.address}
                  />

                   <div className='mx-2'></div>
                   <TextAreaBox 
                    placeholder={"Customer Comment"} 
                    name={"customerComments"}
                    label={"Customer Comment"} 
                    type={"textarea"}
                    onChange={handleChange}
                    value={jobDetails?.customerComments}
                  />
                   
                  
            </div>
            <div className=' flex justify-around'>
            {(userProfile.role == "Admin" || userProfile.role == "console") ?
                   <DatePickerBox
                   name={"bookingDate"}
                   label={"Book Date"}
                   onChange={handleChange}
                   value={jobDetails?.bookingDate}
                  enableFullDate={true}
                 />

                 :
                 <DatePickerBox
                   name={"bookingDate"}
                   label={"Book Date"}
                   onChange={handleChange}
                   value={jobDetails?.bookingDate}
                  
                 />

                  }
                   <div className='mx-2'></div>
                   <InputBox 
                    placeholder={"Job Cost"} 
                    name={"jobCost"}
                    label={"Job Cost"} 
                    type={"number"}
                    onChange={handleChange}
                    value={jobDetails?.jobCost}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Quantity"} 
                    name={"qty"}
                    label={"Quantity"} 
                    type={"number"}
                    onChange={handleChange}
                    value={jobDetails?.qty}
                  />
                  
            </div>
            <div className=' flex justify-around'>
                  <DatePickerBox
                    name={"bookingTime"}
                    label={"Booking Time"}
                    type='time'
                    disableFutureDate={false}
                    onChange={handleChange}
                    value={jobDetails?.bookingTime}
                   
                  />
                   <div className='mx-2'></div>
                   <DropDownBox 
                    dropDownName={"City"}
                    dropDownData={cities}
                    onChange = {(e) => handleDropChange(e,"cityValue","city")}
                    idField='cCID'
                    value={jobDetails?.cityValue}
                  />
                   <div className='mx-2'></div>
                   <DropDownBox 
                    dropDownName={"Job Type"}
                    dropDownData={jobTypes}
                    onChange = {(e) => handleDropChange(e,"jobTypeValue","jobType")}
                    idField='jtID'
                    value={jobDetails?.jobTypeValue}
                  />
                  <div className='mx-2'></div>
                   {/* {userProfile.role == "Admin" || userProfile.role == "console" ?
                   <DropDownBox 
                    dropDownName={"Job Status"}
                    dropDownData={jobStatus}
                    onChange = {(e) => handleDropChange(e,"jobStatusValue","jobStatus")}
                    idField='jSID'
                    value={jobDetails?.jobStatusValue}
                  />
                  :null} */}
                   {userProfile.role == "Admin" || userProfile.role == "console" ?
                   <DropDownBox 
                    dropDownName={"Assign Technician"}
                    dropDownData={agents}
                    onChange = {(e) => handleDropChange(e,"agentValue","agent")}
                    idField='uID'
                    value={jobDetails?.agentValue}
                  />
                  :null}
            </div>
            </div>

            {errors == null ? <p className='text-purple-500 dark:text-gray-900 '>{null}</p> : <p className= ' text-purple-900 dark:text-red-500'><b>Error: {errors}</b></p> } 
            <hr className='bg-purple-100'/>
            <div className='flex justify-around w-full py-2'>
            <div className='w-3/4'></div>
                <ButtonBoxWithoutLoader
                  name='Update'
                  loadingText='Updating'
                  isLoading={isLoading}
                  onClick={() => PassValidation()}
                />
                  <div className='w-3/4'></div>
                  </div>
                 
            </div>
        </section>
      
      </div>
    </div>
    </>
  )
}

export default ModalEditJobDetails
