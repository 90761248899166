import React from 'react'

const SelectTabBtn = ({tabname = "Pending", selectedTabName = "Pending", onClick}) => {
  return (
    <>     
    <button onClick={onClick} className='m-2'>
    <div class="flex flex-wrap items-center gap-4">
       {buildSelectedButton(tabname, selectedTabName)}
    </div>
    </button>   
    </>
  )
}

const buildSelectedButton = (tabname,selectedTabName) =>{
    if(selectedTabName == tabname){
    return(
        <span class="px-4 py-2  text-base rounded-full text-white border-blue-800 border bg-blue-800 ">
            {tabname}
        </span>
    )
    }else{
        return(
            <span class="px-4 py-2  text-base rounded-full text-blue-600 border-blue-600 border bg-blue-200 ">
                {tabname}
            </span>
        )
    }
}

export default SelectTabBtn
