import React, { useEffect, useState } from 'react'
import StatsBox from '../../components/StatsBox'
import TableBox from '../../components/TableBox'
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../APIs/api';
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader';
import { InitiateJobs, UpdateTeam } from '../../redux/reduxStats/sliceDataManagement';
import TableJobsBox from '../../components/TableJobsBox';
import AwaitingJobCard from '../../components/AwaitingJobCard';
import SelectTabBtn from '../../components/SelectTabBtn';

const AwaitingJobsAgent = () => {

  let userProfile = useSelector(state=>state.authReducer.userProfile);
  let jobList = useSelector(state=>state.dataManagementReducer.jobs);

  const dispatchItem = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [jobListData, setJobListData] = useState(null);

    const [pendingJobs, setPendingJobs] = useState([]);
    const [inProgressJob, setInProgressJob] = useState([]);
    const [completedJobs, setCompletedJobs] = useState([]);
    const [cancelledJobs, setCancelledJobs] = useState([]);
    const [agents, setAgents] = useState([]);

    const [selectedTab, setSelectedTab] = useState({tabname: "Pending", title: "Assigned Jobs"},);

    useEffect(() => {
      loadJobs(userProfile.uID);
    }, []);

    useEffect(() => {
      setJobListData(jobList);
    },[jobList])

    const loadJobs = async (uID) => {
      setIsLoading(true);
      let jobs = {};
      try {
        // Create two promises for pending and in-progress jobs
        const pendingJobsPromise = get(`jobs/agent/${uID}/Pending`);
        const inProgressJobsPromise = get(`jobs/agent/${uID}/In-Progress`);
        const completedJobsPromise = get(`jobs/agent/${uID}/Completed`);
        const cancelledJobsPromise = get(`jobs/agent/${uID}/Cancelled`);

        // const agentPromise = get(`account/agents`);
    
        // Use Promise.all to wait for both promises to resolve
        const [pendingJobData, inProgressJobData, completedJobData, cancelledJobData] = await Promise.all([pendingJobsPromise, inProgressJobsPromise, completedJobsPromise, cancelledJobsPromise]);
    
        // Handle the response for pending jobs
        // if (agentListData.response != null) {
        //   console.log(agentListData);
        //   setAgents(agentListData.response);
        // } else {
        //   setAgents([]);
        // }
        
        // Handle the response for pending jobs
        if (pendingJobData.response != null) {
          setPendingJobs(pendingJobData.response);
        } else {
          setPendingJobs([]);
        }
    
        // Handle the response for in-progress jobs
        if (inProgressJobData.response != null) {
          setInProgressJob(inProgressJobData.response);
        } else {
          setInProgressJob([]);
        }

         // Handle the response for completed jobs
        if (completedJobData.response != null) {
          setCompletedJobs(completedJobData.response);
        } else {
          setCompletedJobs([]);
        }

         // Handle the response for cancelled jobs
         if (cancelledJobData.response != null) {
          setCancelledJobs(cancelledJobData.response);
        } else {
          setCancelledJobs([]);
        }

        jobs = {
          pendingJobs: pendingJobData.response,
          inProgressJobs: inProgressJobData.response,
          completedJobs: completedJobData.response,
          cancelledJobs: cancelledJobData.response,
        }

      } catch (error) {

        jobs = {
          pendingJobs: [],
          inProgressJobs: [],
          completedJobs: [],
          cancelledJobs: [],
        }
        // Handle any errors that occurred during the fetch
        console.error("Error loading jobs", error);
        // Potentially set error state here if you have one
      }

      dispatchItem(
        InitiateJobs(
          jobs
        ),
      )
      setIsLoading(false);
    };
    


  const smallTabs = [
    {tabname: "Pending", title: "Assigned Jobs"},
    {tabname: "In-Progress", title: "Jobs In-Progress"},
    {tabname: "Completed", title: "Completed Jobs"},
    {tabname: "Cancelled", title: "Cancelled Jobs"}
  ]
  const selectTab = (value) => {
    setSelectedTab(value)
  }
  return (
    <>
    <div className='w-full bg-purple-700 dark:bg-slate-600'> 
      <div className="p-4 bg-purple-900 dark:bg-gray-800 flex">
        <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <p className=' text-3xl text-white'><b>{selectedTab.title}</b></p>
            </div>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          onClick={() => loadJobs(userProfile.uID)}
          isLoading={isLoading}
        />
        </div>
      </div>
      <div className='w-full my-2 flex px-4'>
        <div className='m-auto'></div>
        <div>
          {smallTabs.map((item, index) => (
            <SelectTabBtn onClick={() => selectTab(item)} tabname={item.tabname} selectedTabName={selectedTab.tabname} />
          ))}
        </div>
      </div>
      {buildList(selectedTab.tabname, userProfile.role, {pendingJobs:jobListData?.pendingJobs, 
        inProgressJob:jobListData?.inProgressJobs, completedJobs: jobListData?.completedJobs, 
        cancelledJobs: jobListData?.cancelledJobs})}
      
    </div>
    </>
  )
}

const buildList = (selectedTabName, role, jobsArrays) => {
  if(selectedTabName == "Pending"){
    return(
      <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        {
          jobsArrays.pendingJobs?.map((item, index) => (
            <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
          ))
        }
      </div>
    )
  }else if(selectedTabName == "In-Progress"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.inProgressJob?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }else if(selectedTabName == "Completed"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.completedJobs?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }else if(selectedTabName == "Cancelled"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.cancelledJobs?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }
}

export default AwaitingJobsAgent
