import React, {useEffect, useState} from 'react';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import InputBox from '../../components/InputBox';
import {post, setAuthToken } from '../../APIs/api';
import { useDispatch } from "react-redux";
import {
  LoginUser
} from '../../redux/reduxStats/sliceAuth';
import { useNavigate } from 'react-router-dom';
import ButtonBox from '../../components/ButtonBox';
const Login = () => {

  const dispatchItem = useDispatch();
  const navigate = useNavigate();

  const [loginState, setLoginState] = useState({
    email: '',
    password: '',
  });
  const [isLogging, setIsLogging] = useState(false);
  const [errors, setErrors] = useState(null);

  // Validation Schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'), // Makes the field required
    password: Yup.string().required('Password is required'),
  });

  // useEffect(() =>{
  //   let loginData = window.localStorage.getItem("loginData");
  //   let bakedData = JSON.parse(loginData);
  //   setAuthToken(bakedData.token);
  //       dispatchItem(
  //         LoginUser(bakedData),
  //       )
  // },[])

  // Handle field change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const LoginAccount = async () => {
    setIsLogging(true);
    setErrors(null);

    try {
      await validationSchema.validate(loginState, { abortEarly: false });
      const userData = await post('auth/login',loginState);

      if(userData.response == null){
        setErrors(userData.message);
      }else{
        setAuthToken(userData.response.token.access.token);
        dispatchItem(
          LoginUser(userData.response),
        )
        window.localStorage.setItem("loginData",JSON.stringify(userData.response));
        navigate('/dashboard');
      }
      // If valid, handle form submission
    } catch (err) {
      if (err.name === 'ValidationError') {
        setErrors("Enter valid email address");
      } else {
        console.error('Error during login:', err);
        setErrors("Something went wrong");
      }
    }
    setIsLogging(false);
  }
  return (
    <>
    <section className="bg-purple-800 py-20 px-4 dark:bg-gray-700 lg:py-[120px] h-screen">
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="relative mx-auto max-w-[525px] overflow-hidden rounded-lg bg-purple-500 dark:bg-gray-900 px-10 pt-16 pb-4 text-center  sm:px-12 md:px-[60px]">
              <div className="mb-10 text-center md:mb-4">
                <h2 className=" text-3xl font-bold dark:text-gray-100 text-gray-100 inline-block">
                  WORK FORCE
                </h2>
              </div>

              <div>
                 {errors == null ? <p className='text-purple-500 dark:text-gray-900 '>-</p> : <p className= ' text-purple-900 dark:text-red-500'><b>{errors}</b></p> }
                <InputBox
                  label={"Email"}
                  type={'text'}
                  placeholder={'Email'}
                  value = {loginState.email}
                  name={'email'}
                  onChange={handleChange}
                />
                <InputBox
                  label={"Password"}
                  type={'password'}
                  placeholder={'Password'}
                  value = {loginState.password}
                  name={'password'}
                  onChange={handleChange}
                />
              </div>
                  <ButtonBox
                    isLoading={isLogging}
                    onClick={() => LoginAccount()}
                    name='Login'
                  />
              <p className="text-base text-body-color dark:text-gray-100 text-white mt-8">
                <b>POWERED BY TWINKSOFT</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default Login
