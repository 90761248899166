import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get, post } from '../../APIs/api';
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader';
import { AddNewCity, InitiateCities } from '../../redux/reduxStats/sliceDataManagement';
import TableCityBox from '../../components/TableCityBox';
import InputBox from '../../components/InputBox';
import DropDownBox from '../../components/DropDownBox';
import * as Yup from 'yup';
const ViewCities = () => {
  let accountId = useSelector(state=>state.authReducer.userProfile.uID);
  let cityList = useSelector(state=>state.dataManagementReducer.cities);
  const dispatchItem = useDispatch();

  
  const tableHeader = [
    "S.No",
    "City",
    "Status",
    "Action",
  ]

    const [isLoading, setIsLoading] = useState(false);
    const [isAddingCity, setIsAddingCity] = useState(false);
    const [currencyCodes, setCurrencyCodes] = useState([]);

    const initialState = {
      name: '',
      calendarId: '',
      currencyCode: '',
      currencyCodeValue: '',
    }
    const [newCity, setNewCity] = useState(initialState);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
      loadCityList();
      loadCurrencyCodes();
    }, []);

    const loadCityList = async () => {
        setIsLoading(true);
        try{
        let cityData = await get(`panel/cities`);
        if(cityData.response != null){
            dispatchItem(
              InitiateCities(cityData.response)
            )
        }
        }catch(error){
          
        }
        setIsLoading(false);
    }

    const loadCurrencyCodes = async () => {
      try{
        let currencyCodesData = await get(`jobs/currencycodes`);
        if(currencyCodesData.response != null){
            setCurrencyCodes(currencyCodesData.response);
        }
      }catch(error){
        setCurrencyCodes(null);
      }
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setNewCity(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleDropChange = (event,valueName, typeName) => {
      const { options, selectedIndex } = event.target;
      const name = options[selectedIndex].text;
      const value = options[selectedIndex].value;
      // Now calling onChange prop with both selected value and name
      setNewCity(prevState => ({
        ...prevState,
        [valueName]: value,
        [typeName]: name,
      }))
    };

    const validationSchema = Yup.object({
      name: Yup.string()
        .required('City name is required')
        .min(2, 'City name must be at least 2 characters long')
        .max(50, 'City name must not exceed 50 characters'),
      calendarId: Yup.string()
        .required('Calendar Id is required')
        .min(10, 'Calendar Id must be at least 10 characters')
        .max(100, 'Calendar Id must not exceed 100 characters'),
      currencyCode: Yup.string()
        .required('Currency is required'),
      currencyCodeValue: Yup.string()
        .required('Currency is required')
    });
  


  const PassValidation = async () => {
    try{
      await validationSchema.validate(newCity, { abortEarly: true });
    }catch(err){
      if (err.name === 'ValidationError') {
        setErrors(err.errors[0]);
      } else {
        console.error('Error during login:', err);
      }
      return;
    }

    AddNewCalendar();
  }

  const AddNewCalendar = async () => {
    setErrors(null);
    setIsAddingCity(true);

    try{
      let newCityData = await post(`panel/cities/add`,newCity);
      if(newCityData.response != null){
        setNewCity(initialState);
        dispatchItem(
          AddNewCity(newCityData.response)
        )
      }else{
        setErrors(newCityData.message);
      }
    }catch(error){
      setErrors("Something went wrong...");
    }

    setIsAddingCity(false);

  }

  
  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600'> 
      <div className="p-4 bg-purple-900 dark:bg-gray-800 flex">
        <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <p className=' text-3xl text-white'><b>Available Cities</b></p>
            </div>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          onClick={() => loadCityList()}
          isLoading={isLoading}
        />
        </div>
      </div>
      <div className="space-y-2">
            <div className='py-4 px-8 w-full items-center'>
                <p className='text-white mb-4'><b>Add new calendar</b></p>
                {errors == null ? <p className='text-purple-500 dark:text-gray-900 '>{null}</p> : <p className= ' text-purple-900 dark:text-red-500'><b>Error: {errors}</b></p> } 
                <div className='w-full md:flex lg:flex justify-around '>
                  <InputBox 
                    placeholder={"City Name"} 
                    name={"name"}
                    label={"City Name"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newCity.name}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Calendar ID"} 
                    name={"calendarId"}
                    label={"Calendar ID"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newCity.calendarId}
                  />
                   <div className='mx-2'></div>
                   <DropDownBox 
                    dropDownName={"City Currency"}
                    dropDownData={currencyCodes}
                    onChange={(e) => handleDropChange(e, "currencyCodeValue", "currencyCode")}
                    idField='cID'
                    value={newCity.currencyCodeValue}
                  />
                   <div className='bg-red-200 self-center mr-4 w-1/2 '>
                    <ButtonBoxWithoutLoader
                      name='Add City'
                      isLoading = {isAddingCity}
                      onClick={() => PassValidation()}
                    />
                  </div>
                </div>
                <hr/>
                
            </div>
      <TableCityBox
        tableHeaderData={tableHeader}
        cityData={cityList}
      />
        </div>
                
    </div>
    </>
  )
}

export default ViewCities