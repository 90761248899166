import React, { useEffect, useState } from 'react'
import StatsBox from '../../components/StatsBox'
import { useSelector } from 'react-redux';
import { get } from '../../APIs/api';

const Dashboard = () => {
  let userProfile = useSelector(state=>state.authReducer.userProfile);
  let teamList = useSelector(state=>state.dataManagementReducer.teams);
  const [jobStats, setJobStats] = useState({});
  const [teamJobStats, setTeamJobStats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStatsLoaded, setIsStatsLoaded] = useState(false);

  useEffect(()=>{
    LoadJobStats(userProfile.uID);
  },[userProfile])

  const tabs = [
    {buttonName: "Completed"},
    {buttonName: "In-Progress"},
    {buttonName: "Pending"},
    {buttonName: "Cancelled"},
  ]

  const LoadJobStats = async (uID) => {
    setIsLoading(true);
    try{
      let myJobStats = await get(`jobs/stats/${uID}`);
      let teamJobStats = null;
      if(userProfile.role == "Admin" || userProfile.role == "console"){
         teamJobStats = await get(`jobs/stats`);
      }

      if (myJobStats.response != null) {
        setJobStats(myJobStats.response);
        setIsStatsLoaded(true);
      } else {
        setJobStats({});
      }

      if(teamJobStats.response != null){
        setTeamJobStats(teamJobStats.response);
      }else{
        setTeamJobStats([]);
      }

    }catch(error){
      console.log(error);
    }

    setIsLoading(false);
  }

  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600 h-screen'> 
      <div className=' p-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full justify-around'>
        {isStatsLoaded == false ? tabs.map((item, index) => (
          <StatsBox key={index}
            name={item.buttonName}
            value={0}
            isLoading={isLoading}
          />   
        ))
      :
      Object.keys(jobStats).map((key) => (
        <StatsBox key={key}
          name={jobStats[key].jobStatus}
          value={jobStats[key].totalJobs}
          isLoading={isLoading}
        />   
      ))
      }     
      </div>
      {userProfile.role == "Admin" || userProfile.role == "console" ? 
      <div className=' p-5 w-full justify-around'>
      <div className=" w-full overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                    WorkForce - Team Sales
                </h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">
                    Details and informations.
                </p>
            </div>
            <div className="border-t border-gray-200">
                  {teamJobStats.map((item, index) => {
                    <dl>
                    <div className="px-4 py-5 flex bg-gray-50 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                        Jobs Completed
                    </dt>
                    <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        0
                    </dd>  
                    <dt className="text-sm font-medium text-gray-500">
                    Jobs Cancelled
                    </dt>
                    <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        0
                    </dd>                     
                </div>
                </dl>
                  })}
                <dl>
                    <div className="px-4 py-5 flex bg-gray-50 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Jobs Completed
                        </dt>
                        <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                            {teamJobStats[0]?.job1?.totalJobs}
                        </dd>  
                        <dt className="text-sm font-medium text-gray-500">
                        Jobs Cancelled
                        </dt>
                        <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        {teamJobStats[1]?.job4?.totalJobs}
                        </dd>                     
                    </div>
                    <div className="px-4 py-5 flex bg-white sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Jobs In-Progress
                        </dt>
                        <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        {teamJobStats[0]?.job2?.totalJobs}
                        </dd>  
                        <dt className="text-sm font-medium text-gray-500">
                          Jobs Pending
                        </dt>
                        <dd className="mt-1 mx-4 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
                        {teamJobStats[1]?.job3?.totalJobs}
                        </dd>                     
                    </div>
                    
                </dl>
            </div>
        </div>   
      </div>
      :null}
    </div>
    </>
  )
}

export default Dashboard
