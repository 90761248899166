import { useEffect } from "react";
import Navigation from "./navigations/Navigation";
import { setAuthToken } from "./APIs/api";
import { useDispatch } from "react-redux";
import { LoginUser } from "./redux/reduxStats/sliceAuth";

function App() {
  const dispatchItem = useDispatch();
  useEffect(()=>{
    let loginData = window.localStorage.getItem("loginData");
    let bakedData = JSON.parse(loginData);
    setAuthToken(bakedData?.token.access.token);
    dispatchItem(
          LoginUser(bakedData),
        )
  },[])

  return (
   <>
     <Navigation />
   </>
  );
}

export default App;
