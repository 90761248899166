import React, { useEffect, useState } from 'react'
import BigButtonBox from '../../components/BigButtonBox'
import InputBox from '../../components/InputBox'
import DropDownBox from '../../components/DropDownBox'
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader'
import { useDispatch, useSelector } from 'react-redux'
import {
  ScreenLockModal
} from '../../redux/reduxStats/sliceModal';
import * as Yup from 'yup';
import DatePickerBox from '../../components/DatePickerBox'
import { get, post } from '../../APIs/api'
import { AddNewMember } from '../../redux/reduxStats/sliceDataManagement'

const AddTeamMember = () => {

  const dispatchItem = useDispatch();
  let userProfile = useSelector(state=>state.authReducer.userProfile);
  const [roles, setRoles] = useState(null);
  useEffect(() => {
      loadDropDown(userProfile.uID);
  }, [userProfile.uID]);

  const loadDropDown = async (uID) => {
      let accountRoles = await get(`auth/roles/${uID}`);
      if(accountRoles.response != null){
          setRoles(accountRoles.response);
      }
  }

  const initialState = {
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    phoneNumber: "",
    dob: "",
    accountType: "",
    accountName: "",
    salary: 1000,
    createrName: userProfile.firstName + " " + userProfile.lastName,
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'First name must be at least 2 characters long')
      .max(50, 'First name must be less than 50 characters long'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Last name must be at least 2 characters long')
      .max(50, 'Last name must be less than 50 characters long'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Password must contain at least one uppercase letter, one lowercase letter, and one number"),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]{10,}$/, "Invalid phone number, must be at least 10 digits"),
    dob: Yup.date()
      .required('Date of birth is required')
      .max(new Date(), 'Date of birth cannot be in the future'),
    accountName: Yup.string()
      .required('Account type is required')
      .oneOf(['Member', 'Technician', 'Admin'], 'Invalid account type'),
    salary: Yup.number()
      .required('Amount is required')
      .min(1000, 'Amount must be at least PKR 1000')
      .max(10000, 'Amount must not exceed PKR 10000')
  });
  
  const [newMember, setNewMember] = useState(initialState);
  const [confirmPassword, setConfirmPassword] = useState(initialState.password)
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(null);

    // Handle field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewMember(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

    const screenLock = (value) => {
      setIsLoading(value);

       if(value === true){ 
        AddMember();
       }else{
        setNewMember(initialState);
        dispatchItem(
          ScreenLockModal(false),
          )
       }
    }

    useEffect(()=>{
      if(errors != null){
        setIsLoading(false);
      }
    },[errors])

    const AddMember = async () => {
      setErrors(null);

      try{
        if(newMember.dob == ''){
          setErrors("Select date of birth.")
          return;
        }
        await validationSchema.validate(newMember, { abortEarly: true });
        dispatchItem(
          ScreenLockModal(true),
          )

        let newUser = await post('account/create',newMember);
        alert(newUser.message);

        if(newUser.response != null){
          newUser = newUser.response;
          dispatchItem(
            AddNewMember(
              {
                uID: newUser.uID,
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                fullName: newUser.firstName +" "+ newMember.lastName,
                email: newUser.email,
                dob: newUser.dob,
                phoneNumber: newUser.phoneNumber,
                accountName: newUser.accountName,
                salary: newUser.salary,
                status: "Active",
              }
            )
          )
        }
      }catch (err) {
        if (err.name === 'ValidationError') {
          setErrors(err.errors[0]);
        } else {
          console.error('Error during login:', err);
        }

        dispatchItem(
          ScreenLockModal(false),
          )
        return;
        
    }
    screenLock(false);
  }
  const handleDropChange = (event) => {
    const { options, selectedIndex } = event.target;
    const name = options[selectedIndex].text;
    const value = options[selectedIndex].value;
    // Now calling onChange prop with both selected value and name
    setNewMember(prevState => ({
      ...prevState,
      accountType: value,
      accountName: name,
    }))
  };

  return (
    <>
      <section className="w-full bg-purple-800 dark:bg-slate-600">
   
        <div className="p-4 bg-purple-900 dark:bg-gray-800 ">
            <div className="max-w-sm mx-auto md:w-full md:mx-0">
                <div className="inline-flex items-center space-x-4">
                <p className=' text-3xl text-white'><b>Add New Team Member</b></p>
                </div>
            </div>
        </div>
        <div className="space-y-2">
            <div className='py-4 px-8 w-full items-center'>
                <p className='text-white mb-4'><b>Personal Info</b></p>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"First Name"} 
                    name={"firstName"}
                    label={"First Name"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newMember.firstName}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Last Name"} 
                    name={"lastName"}
                    label={"Last Name"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newMember.lastName}
                  />
                </div>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"Email"} 
                    name={"email"}
                    label={"Email"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newMember.email}
                  />
                   <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Phone Number"} 
                    name={"phoneNumber"}
                    label={"Phone Number"} 
                    type={"text"}
                    onChange={handleChange}
                    value={newMember.phoneNumber}
                  />
                </div>
                <div className='w-3/4 flex justify-around'>
                  <DatePickerBox
                    name={"dob"}
                    label={"Date of birth"}
                    onChange={handleChange}
                    value={newMember.dob}
                    disableFutureDate={true}
                  />
                   <div className='mx-2'></div>
                   <InputBox 
                    placeholder={"Salary/Sales"} 
                    name={"salary"}
                    label={"Salary Amount"} 
                    type={"number"}
                    onChange={handleChange}
                    value={newMember.salary}
                  />
                </div>
            </div>
            <hr className='bg-purple-100'/>

            {/* //new Div */}
            <div className=' py-4 px-8 w-full items-center'>
                <p className='text-white mb-4'><b>Sensitive Information</b></p>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"Password"} 
                    name={"password"}
                    label={"Password"} 
                    type={"password"}
                    onChange={handleChange}
                    value={newMember.password}
                  />
                   <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Confirm Password"} 
                    name={"confirmPassword"}
                    label={"Confirm Password"} 
                    type={"password"}
                    onChange={(e) => setConfirmPassword(e.value)}
                    value={confirmPassword}
                  />
                </div>
                <div className='w-3/4 flex justify-around'>
                  <DropDownBox 
                    onChange={handleDropChange}
                    dropDownName={"Account Type"}
                    dropDownData={roles}
                    idField='rID'
                  />
                </div>
            </div>
            <hr className='bg-purple-100'/>
            
            {/* submit button */}
            <div className=' py-4 px-8 w-full items-center flex'>
              <div className='w-2/3 pr-4'>
              {errors == null ? <p className='text-purple-500 dark:text-gray-900 '>-</p> : <p className= ' text-purple-900 dark:text-red-500'><b>Error: {errors}</b></p> } 
              </div>
              <div className='ml-auto w-1/3'>
                <ButtonBoxWithoutLoader 
                  isLoading={isLoading}
                  name='Proceed'
                  onClick={() => screenLock(true)}
                />
              </div>
            </div>
            
        </div>
                    
      </section>
    </>
  )
}

export default AddTeamMember
