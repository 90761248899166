import React from 'react'

const TableHeaderBox = ({tableHeader}) => {
  return (
    <>
     <thead>
        <tr>
            {tableHeader.map((item, index) => (
             <th key={index} scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                {item}
             </th>
            ))}
        </tr>
    </thead>
    </>
  )
}

export default TableHeaderBox
