import React from 'react'

const TextAreaBox = ({ type, placeholder, name, value, onChange, label }) => {
  return (
    <>
        <div className="mb-6 w-full items-star text-left">
          <label className='text-gray-100 dark:text-white text-sm pl-2'>{label}</label>
          <textarea
            type = {type}
            placeholder = {placeholder}
            value = {value}
            name = {name}
            onChange={onChange}
            className="w-full bg-white rounded-md border border-stroke bg-transparent px-3 py-3 text-base text-body-color outline-none focus:border-primary focus-visible:shadow-none dark:border-dark-3 dark:text-gray"
          />
        </div>
        </>
      );
}

export default TextAreaBox
