import React, { useEffect, useState } from 'react'
import DatePickerBox from '../../components/DatePickerBox'
import InputBox from '../../components/InputBox'
import TextAreaBox from '../../components/TextAreaBox'
import DropDownBox from '../../components/DropDownBox'
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader'
import { get, post } from '../../APIs/api'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup';
import { ScreenLockModal } from '../../redux/reduxStats/sliceModal'

const BookSale = () => {
  let userProfile = useSelector(state=>state.authReducer.userProfile);
  const dispatchItem = useDispatch();
  
  const [jobTypes, setJobTypes] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefresing, setIsRefresing] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
      loadDropDown();
      loadCities();
  }, []);

  const reloadDropDowns = async () =>{
    setIsRefresing(true);
    try{
      await loadDropDown();
      await loadCities();
      
    }catch(error){
      setIsRefresing(false);
    }

    setIsRefresing(false);
  }
  
  const loadDropDown = async () => {
    try{
      let jobTypesData = await get(`jobs/types`);
      if(jobTypesData.response != null){
          setJobTypes(jobTypesData.response);
      }
    }catch(error){
      setJobTypes(null);
    }
  }

  const loadCities = async () => {
    try{
      let citesData = await get(`panel/cities`);
      if(citesData.response != null){
          setCities(citesData.response);
      }
    }catch(error){
      setCities(null);
    }
  }


  const initialState = {
    customerName: "",
    phoneNumber: "",
    address: "",
    jobTypeValue: "",
    jobType: "",
    jobCost: "",
    qty: 1,
    cityValue: "",
    city: "",
    agent: null,
    customerComments: "",
    bookingDate: "",
    bookingTime: "",
    bookedBy: userProfile.uID,
    bookedByName: userProfile.firstName + " " + userProfile.lastName,
    userSalary: userProfile.salary,
  }

  const [bookingData, setBookingData] = useState(initialState);

      // Handle field change
      const handleChange = (e) => {
        const { name, value } = e.target;
        setBookingData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };

      const handleDropChange = (event,valueName, typeName) => {
        const { options, selectedIndex } = event.target;
        const name = options[selectedIndex].text;
        const value = options[selectedIndex].value;
        // Now calling onChange prop with both selected value and name
        setBookingData(prevState => ({
          ...prevState,
          [valueName]: value,
          [typeName]: name,
        }))
      };

  const validationSchemaForMember = Yup.object({
    customerName: Yup.string()
      .required('Customer name is required')
      .min(2, 'Customer name must be at least 2 characters long')
      .max(50, 'Customer name must not exceed 50 characters'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, 'Phone number must be at least 10 digits long')
      .max(15, 'Phone number must not exceed 15 digits'),
    address: Yup.string()
      .required('Address is required')
      .min(10, 'Address must be at least 10 characters long'),
    jobTypeValue: Yup.string()
      .required('Job type is required'),
    jobCost: Yup.number()
      .typeError('Please enter job cost')
      .required('Job cost is required')
      .min(0, 'Job cost cannot be negative'),
    qty: Yup.number()
      .typeError('Please enter quantity')
      .required('Quantity is required')
      .min(1, 'Quantity cannot be negative'),
    cityValue: Yup.string()
      .required('City is required'),
    agent: Yup.mixed()
      .nullable(true) // Assuming 'agent' can be null and is not required
      .notRequired(),
    customerComments: Yup.string()
      .max(500, 'Comments must not exceed 500 characters')
      .notRequired(),
    bookingDate: Yup.date()
      .typeError('Please provide a valid booking date.') // Custom message for type error
      .required('Booking date is required')
      .min(new Date(), 'Booking date cannot be in the past'),
    bookingTime: Yup.string()
      .typeError('Please provide a valid time.') // Custom message for type error
      .required('Booking time is required'),
  });

  const validationSchemaForAdmin = Yup.object({
    customerName: Yup.string()
      .required('Customer name is required')
      .min(2, 'Customer name must be at least 2 characters long')
      .max(50, 'Customer name must not exceed 50 characters'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .min(10, 'Phone number must be at least 10 digits long')
      .max(15, 'Phone number must not exceed 15 digits'),
    address: Yup.string()
      .required('Address is required')
      .min(10, 'Address must be at least 10 characters long'),
    jobTypeValue: Yup.string()
      .required('Job type is required'),
    jobCost: Yup.number()
      .typeError('Please enter job cost')
      .required('Job cost is required')
      .min(0, 'Job cost cannot be negative'),
    qty: Yup.number()
      .typeError('Please enter quantity')
      .required('Quantity is required')
      .min(1, 'Quantity cannot be negative'),
    cityValue: Yup.string()
      .required('City is required'),
    agent: Yup.mixed()
      .nullable(true) // Assuming 'agent' can be null and is not required
      .notRequired(),
    customerComments: Yup.string()
      .max(500, 'Comments must not exceed 500 characters')
      .notRequired(),
    bookingDate: Yup.date()
      .typeError('Please provide a valid booking date.') // Custom message for type error
      .required('Booking date is required'),
    bookingTime: Yup.string()
      .typeError('Please provide a valid time.') // Custom message for type error
      .required('Booking time is required'),
  });


  const PassValidation = async () => {
    try{
      console.log("role--->", userProfile.role);
      if(userProfile.role == "Admin" || userProfile.role == "console"){
        await validationSchemaForAdmin.validate(bookingData, { abortEarly: true });
      }else if(userProfile.role == "Member"){
        await validationSchemaForMember.validate(bookingData, { abortEarly: true });
      }
    }catch(err){
      if (err.name === 'ValidationError') {
        setErrors(err.errors[0]);
      } else {
        console.error('Error during login:', err);
      }
      return;
    }

    BookJob();
  }

  const BookJob = async () => {
    setErrors(null);
    setIsLoading(true);
    dispatchItem(
      ScreenLockModal(true),
    )

    try{
      bookingData.createdAt = new Date();
      let newJobData = await post(`jobs/add`,bookingData);
      if(newJobData.response != null){
        alert(`Congratz! ${newJobData.message}`);
        setBookingData(initialState);
      }else{
        alert(`Error: ${newJobData.message}`)
      }
    }catch(error){
      console.log(error);
      alert(`Something went wrong...`);
    }

    dispatchItem(
      ScreenLockModal(false),
    )
    setIsLoading(false);
  }

  return (
   <>
    <section className="w-full bg-purple-800 dark:bg-slate-600">
    <div className="p-4 bg-purple-900 dark:bg-gray-800 flex ">
        <div className="inline-flex items-center space-x-4">
            <p className=' text-3xl text-white'><b>Book your new customer job</b></p>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          isLoading={isRefresing}
          onClick={() => reloadDropDowns()}
        />
        </div>
    </div>
    <div className="space-y-2">
    <div className='py-4 px-8 w-full items-center'>
                <p className='text-white mb-4'><b>Job Booking Details</b></p>
                <div className='w-3/4 flex justify-around'>
                  <InputBox 
                    placeholder={"Customer Name"} 
                    name={"customerName"}
                    label={"Customer Name"} 
                    type={"text"}
                    onChange={handleChange}
                    value={bookingData.customerName}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Phone Number"} 
                    name={"phoneNumber"}
                    label={"Phone Number"} 
                    type={"text"}
                    onChange={handleChange}
                    value={bookingData.phoneNumber}
                  />
                </div>
                <div className='w-3/4 flex justify-around'>
                  <TextAreaBox 
                    placeholder={"Customer Address"} 
                    name={"address"}
                    label={"Customer Address"} 
                    type={"textarea"}
                    onChange={handleChange}
                    value={bookingData.address}
                  />

                   <div className='mx-2'></div>
                   <TextAreaBox 
                    placeholder={"Customer Comment"} 
                    name={"customerComments"}
                    label={"Customer Comment"} 
                    type={"textarea"}
                    onChange={handleChange}
                    value={bookingData.customerComments}
                  />
                   
                  
                </div>
                <div className='w-3/4 flex justify-around'>
                  {(userProfile.role == "Admin" || userProfile.role == "console") ?
                   <DatePickerBox
                   name={"bookingDate"}
                   label={"Book Date"}
                   onChange={handleChange}
                   value={bookingData.bookingDate}
                  enableFullDate={true}
                 />

                 :
                 <DatePickerBox
                   name={"bookingDate"}
                   label={"Book Date"}
                   onChange={handleChange}
                   value={bookingData.bookingDate}
                  
                 />

                  }
                   <div className='mx-2'></div>
                   <InputBox 
                    placeholder={"Job Cost"} 
                    name={"jobCost"}
                    label={"Job Cost"} 
                    type={"number"}
                    onChange={handleChange}
                    value={bookingData.jobCost}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Quantity"} 
                    name={"qty"}
                    label={"Quantity"} 
                    type={"number"}
                    onChange={handleChange}
                    value={bookingData.qty}
                  />
                </div>
                <div className='w-3/4 flex justify-around'>
                  <DatePickerBox
                    name={"bookingTime"}
                    label={"Booking Time"}
                    type='time'
                    disableFutureDate={false}
                    onChange={handleChange}
                    value={bookingData.bookingTime}
                   
                  />
                   <div className='mx-2'></div>
                   <DropDownBox 
                    dropDownName={"City"}
                    dropDownData={cities}
                    onChange = {(e) => handleDropChange(e,"cityValue","city")}
                    idField='cCID'
                    value={bookingData.cityValue}
                  />
                  
                   <div className='wx-2'></div>
                  <DropDownBox 
                    dropDownName={"Job Type"}
                    dropDownData={jobTypes}
                    onChange = {(e) => handleDropChange(e,"jobTypeValue","jobType")}
                    idField='jtID'
                    value={bookingData.jobTypeValue}
                  />
                </div>
                <hr className='bg-purple-100'/>
                <div className=' py-4 px-8 w-full items-center flex'>
                  
              <div className='w-2/3 pr-4'>
              {errors == null ? <p className='text-purple-500 dark:text-gray-900 '>{null}</p> : <p className= ' text-purple-900 dark:text-red-500'><b>Error: {errors}</b></p> } 
              </div>
              <div className='ml-auto w-1/3'>
                <ButtonBoxWithoutLoader 
                  isLoading={isLoading}
                  name='Book Sale'
                  onClick={() => PassValidation()}
                />
              </div>
            </div>
            </div>
            <hr className='bg-purple-100'/>
    </div>
    </section>
   </>
  )
}

export default BookSale
