import React from 'react'
import BigButtonBox from '../../components/BigButtonBox'
import { useSelector } from 'react-redux';

const ControlManagementPanel = () => {
  let accountRole = useSelector(state=>state.authReducer.userProfile.role);
  const adminButtons = [
    {buttonName: "Cities", screenName: "cities"},
  ]

  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600'> 
      <div className='p-5 grid grid-cols-3 gap-4 w-full justify-around'>
          {
             adminButtons.map((item, index) => (
              <BigButtonBox buttonName = {item.buttonName} key={index} screenName={item.screenName} />
            )) 
          }
      </div>
    </div>
    </>
  )

}

export default ControlManagementPanel
