import React from 'react'
import { ClipLoader } from 'react-spinners'

const ButtonBox = ({isLoading, onClick, name="button"}) => {
  return (
    <>
        {isLoading ?
            <ClipLoader
            color="#36d7b7"
            loading={isLoading}
            size={50}
            cssOverride={{
                margin:'auto'
            }}
            /> :
            <button type = "submit" className='dark:bg-blue-800 bg-purple-800 hover:bg-purple-900 dark:hover:bg-blue-900 w-full rounded-md border px-5 py-3' 
            onClick={onClick}>
            <p className='text-white'>
            <b>
                {name}
            </b>
            </p>
        </button>
        }
    </>
  )
}

export default ButtonBox
