import React from 'react'
import {Routes, Route, Navigate } from 'react-router-dom'
import Login from '../screens/LoginScreen/Login'

const RouteAuth = () => {
  return (
    <>
    <Routes>
        <Route path="/login" element={<Login/>} />
        <Route path="*" element={<Navigate replace to={"/login"} />} />
    </Routes> 
    </>
  )
}

export default RouteAuth