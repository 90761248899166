import React from 'react'
import { ClipLoader } from 'react-spinners'

const ButtonBoxWithoutLoader = ({isLoading = false, loadingText = "Proceeding", onClick, name="button"}) => {
  return (
    <>
      <button type = "submit" className='dark:bg-blue-800 bg-purple-800 hover:bg-purple-900 dark:hover:bg-blue-900 w-full rounded-md border px-5 py-3' 
        onClick={onClick}>
        <p className='text-white'>
        <b>
            { isLoading ? loadingText: name}
        </b>
        </p>
      </button>
    </>
  )
}

export default ButtonBoxWithoutLoader
