import React from 'react'
import { useNavigate } from 'react-router-dom';

const BigButtonBox = ({buttonName = "Button", screenName}) => {
    const navigate = useNavigate();
    const navigateToScreen = (value) => {
        navigate(`/${value}`);
    }
  return (
    <>  
        <button onClick={() => navigateToScreen(screenName)}>
            <div className="px-6 py-6 bg-purple-700 shadow-lg rounded-2xl w-84 mx-4 dark:bg-gray-800">
                <p className=" text-center text-2xl text-md text-white dark:text-gray-50">
                    <b>{buttonName}</b>
                </p>
            </div>
        </button>
    </>
  )
}

export default BigButtonBox
