import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    teams: null,
    cities: [],
    jobs:{
        pendingJobs: [],
        inProgressJobs: [],
        completedJobs: [],
        cancelledJobs: [],
    }
}

export const sliceDataManagement = createSlice({
    name: 'dataManagementStats',
    initialState,
    reducers: {
        UpdateTeam:{
            reducer(state, action){
                state.teams = action.payload
            },
            prepare(teamData) {
                return{
                    payload: teamData
                }
            }
        },
        RemoveMember:{
            reducer(state, action){
                state.teams = state.teams.filter(teamMember => teamMember.uID !== action.payload);
            },
            prepare(uID) {
                return{
                    payload: uID
                }
            }
        },
        AddNewMember:{
            reducer(state, action){
                state.teams.push(action.payload);
            },
            prepare(teamMember) {
                return{
                    payload: teamMember
                }
            }
        },
        UpdateStatus:{
            reducer(state, action){
                state.teams = state.teams.map(element => element.uID === action.payload.uID ? {
                    ...element,
                    status:action.payload.status
                }:element);
                //update specific element
            },
            prepare(uID, status) {
                return{
                    payload: {
                        uID,
                        status
                    }
                }
            }
        },
        UpdateTeamElement:{
            reducer(state, action){
                state.teams = state.teams.map(element => 
                    element.uID === action.payload.uID ? { ...element, ...action.payload } : element
                );
                //update specific element
            },
            prepare(teamElement) {
                return{
                    payload: teamElement
                }
            }
        },
        InitiateCities:{
            reducer(state, action){
                state.cities = action.payload
            },
            prepare(cityData) {
                return{
                    payload: cityData
                }
            }
        },
        AddNewCity:{
            reducer(state, action){
                state.cities.push(action.payload);
            },
            prepare(cityData) {
                return{
                    payload: cityData
                }
            }
        },
        RemoveExistCity:{
            reducer(state, action){
                state.cities = state.cities.filter(city => city.cCID !== action.payload);
            },
            prepare(cCID) {
                return{
                    payload: cCID
                }
            }
        },
        UpdateExistCity:{
            reducer(state, action){
                state.cities = state.cities.map(element => 
                    element.cCID === action.payload.cCID ? { ...element, ...action.payload } : element
                );
            },
            prepare(cityData) {
                return{
                    payload: cityData
                }
            }
        },
        InitiateJobs:{
            reducer(state, action){
                state.jobs = action.payload
            },
            prepare(jobsData) {
                return{
                    payload: jobsData
                }
            }
        },
        RemoveJob:{
            reducer(state, action){
                if(action.payload.jobType == "Pending"){
                    state.jobs.pendingJobs = state.jobs.pendingJobs.filter(job => job.jID !== action.payload.jID);
                }else if(action.payload.jobType == "In-Progress"){
                    state.jobs.inProgressJobs = state.jobs.inProgressJobs.filter(job => job.jID !== action.payload.jID);
                }
            },
            prepare(jobType, jID) {
                return{
                    payload: {
                        jobType,
                        jID
                    }
                }
            }
        },
        UpdateExistJob:{
            reducer(state, action){
                if(action.payload.jobStatus == "Pending"){
                    state.jobs.pendingJobs = state.jobs.pendingJobs.map(element => 
                        element.jID === action.payload.jID ? { ...element, ...action.payload } : element
                    );
                }else if(action.payload.jobStatus == "In-Progress"){
                    state.jobs.inProgressJobs = state.jobs.inProgressJobs.map(element => 
                        element.jID === action.payload.jID ? { ...element, ...action.payload } : element
                    );
                }
            },
            prepare(jobData) {
                return{
                    payload: jobData
                }
            }
        },
        UpdateExistJobStatus: {
            reducer(state, action) {
              if (action.payload.oldStatus === "Pending") {
                state.jobs.pendingJobs = state.jobs.pendingJobs.filter(job => job.jID !== action.payload.jobData.jID);
              } else if (action.payload.oldStatus === "In-Progress") {
                state.jobs.inProgressJobs = state.jobs.inProgressJobs.filter(job => job.jID !== action.payload.jobData.jID);
              }
          
              if (action.payload.jobData.jobStatus === "In-Progress") {
                  state.jobs.inProgressJobs = state.jobs.inProgressJobs == null ? [] : state.jobs.inProgressJobs;
                if (Array.isArray(state.jobs.inProgressJobs)) {
                  state.jobs.inProgressJobs.push(action.payload.jobData);
                }
              } else if (action.payload.jobData.jobStatus === "Cancelled") {
                state.jobs.cancelledJobs = state.jobs.cancelledJobs == null ? [] : state.jobs.cancelledJobs;
                if (Array.isArray(state.jobs.cancelledJobs)) {
                  state.jobs.cancelledJobs.push(action.payload.jobData);
                }
              } else if (action.payload.jobData.jobStatus === "Completed") {
                state.jobs.completedJobs = state.jobs.completedJobs == null ? [] : state.jobs.completedJobs;
                if (Array.isArray(state.jobs.completedJobs)) {
                  state.jobs.completedJobs.push(action.payload.jobData);
                }
              } else if (action.payload.jobData.jobStatus === "Pending") {
                state.jobs.pendingJobs = state.jobs.pendingJobs == null ? [] : state.jobs.pendingJobs;
                if (Array.isArray(state.jobs.pendingJobs)) {
                  state.jobs.pendingJobs.push(action.payload.jobData);
                }
              }
            },
            prepare(oldStatus, jobData) {
              return {
                payload: {
                  oldStatus,
                  jobData
                }
              };
            }
          }
          
    }
});

export const { 
    UpdateTeam, 
    RemoveMember, 
    AddNewMember, 
    UpdateStatus,
    UpdateTeamElement,
    InitiateCities,
    AddNewCity,
    RemoveExistCity,
    UpdateExistCity,
    InitiateJobs,
    RemoveJob,
    UpdateExistJob,
    UpdateExistJobStatus
} = sliceDataManagement.actions;

export default sliceDataManagement.reducer;