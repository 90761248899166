import React from 'react'
import ProfileInfo from '../../components/ProfileInfo'
import { useSelector } from 'react-redux';

const Profile = () => {
    let userProfile = useSelector(state=>state.authReducer.userProfile);
  return (
    <>
        <div className='w-full bg-purple-900 dark:bg-slate-600'> 
            <div className=' p-5 flex justify-center'>   
                <ProfileInfo 
                    userProfile={userProfile}
                />
            </div>
        </div>
    </>
  )
}

export default Profile
