import React from 'react'

const StatusCard = ({statusCode, onClick}) => {
  return (
    <>
    {buildStatus(statusCode, onClick)}
    </>
  )
}

const buildStatus = (statusCode, onClick) =>{
    if(statusCode == "In-Progress"){
        return(
            <button onClick={onClick}>
            <span className="flex items-center px-2 py-1 text-xs font-semibold text-blue-600 bg-blue-200 rounded-md">
                IN-PROGRESS
            </span>
            </button>
        )
    }else if(statusCode == "Pending"){
        return(
            <button onClick={onClick}>
            <span className="flex items-center px-2 py-1 text-xs font-semibold text-yellow-600 bg-yellow-200 rounded-md">
                PENDING
            </span>
            </button>
        )
    }else if(statusCode == "Cancelled"){
        return(
            <span className="flex items-center px-2 py-1 text-xs font-semibold text-red-600 bg-red-200 rounded-md">
                CANCELLED
            </span>
        )
    }else if(statusCode == "Completed"){
        return(
        <span className="flex items-center px-2 py-1 text-xs font-semibold text-green-700 rounded-md bg-green-50">
            COMPLETED
        </span>
        )
    }
}

export default StatusCard
