import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userProfile: {
        uID: "",
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        token: {}
    }
}

export const sliceAuth = createSlice({
    name: 'authStats',
    initialState,
    reducers: {
        LoginUser:{
            reducer(state, action){
                state.userProfile = action.payload
            },
            prepare(userProfile) {
                return{
                    payload: userProfile
                }
            }
        },

    }
});

export const { LoginUser } = sliceAuth.actions;

export default sliceAuth.reducer;