import { configureStore, applyMiddleware } from "@reduxjs/toolkit";

import authReducer from '../reduxStats/sliceAuth';
import modalReducer from '../reduxStats/sliceModal';
import dataManagementReducer from '../reduxStats/sliceDataManagement'

export const store = configureStore({
    reducer: {
        authReducer,
        modalReducer,
        dataManagementReducer
    }
})