import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ButtonBox from '../components/ButtonBox';
import { AreYouSureDeleteJobModal, AreYouSureModal } from '../redux/reduxStats/sliceModal';
import { get, post } from '../APIs/api';
import { RemoveJob, RemoveMember } from '../redux/reduxStats/sliceDataManagement';

const ModalAreYouSureDeleteJob = () => {
  let modalPopUp = useSelector(state=>state.modalReducer.customModal.areYouSureDeleteJob);
  let userProfile = useSelector(state=>state.authReducer.userProfile);
  let selectedData = useSelector(state=>state.modalReducer.selectedData);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const dispatchItem = useDispatch();

  const ClosePopUp = () => {
    setIsDeleted(false);
    dispatchItem(
      AreYouSureDeleteJobModal(false)
    )
  }

  const DeleteJob = async (jobDetails) => {
    setIsLoading(true);

    let jobBody = {
      uID:userProfile.uID,
      cityValue: selectedData.cityValue,
      eventId: selectedData.eventId,
      jID: selectedData.jID,
      jobType: selectedData.jobType,
    }

    try{
      const jobData = await post(`jobs/delete`,jobBody);

      if(jobData.response == true){
        setIsDeleted(true);
        dispatchItem(
          RemoveJob(selectedData.jobStatus, selectedData.jID),
        )
      }else{
        alert(jobData.message);
      }
    }catch(error){
      setIsLoading(false);
    }

    setIsLoading(false);
  }

  return (
    <>
    <div
      className={`
        fixed inset-0 flex justify-center items-center transition-colors
        ${modalPopUp ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`
           rounded-xl p-6 transition-all
          ${modalPopUp ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
      {!isDeleted ?
      <div className="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-gray-800">
          <div className="w-full h-full text-center">
              <svg onClick={() => ClosePopUp()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 ml-auto hover:text-white text-gray-700" viewBox="0 0 1792 1792">
                  <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                  </path>
              </svg>
              <div className="flex flex-col justify-between h-full">
                  <svg width="40" height="40" className="w-12 h-12 m-auto mt-4 text-indigo-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                      </path>
                  </svg>
                  <p className="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                      Delete Job Lead
                  </p>
                  <p className="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                      Are you sure you want to delete this {selectedData?.jobType} job ?
                  </p>
                  <div className="flex items-center justify-between w-full gap-4 mt-8">
                    <ButtonBox
                      name='Delete'
                      isLoading={isLoading}
                      onClick={() => DeleteJob(selectedData)}
                    />
                  </div>
              </div>
          </div>
      </div>
      :
      <div className="w-64 p-4 m-auto bg-white shadow-lg rounded-2xl dark:bg-gray-800">
          <div className="w-full h-full text-center">
              <div className="flex flex-col justify-between h-full">
                  <svg width="40" height="40" className="w-12 h-12 m-auto mt-4 text-indigo-500" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M704 1376v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0v-704q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm-544-992h448l-48-117q-7-9-17-11h-317q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5h-832q-66 0-113-58.5t-47-141.5v-952h-96q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z">
                      </path>
                  </svg>
                  <p className="mt-4 text-xl font-bold text-gray-800 dark:text-gray-200">
                      Job Deleted Successfully
                  </p>
                  <p className="px-6 py-2 text-xs text-gray-600 dark:text-gray-400">
                      {selectedData?.jobType}
                  </p>
                  <div className="flex items-center justify-between w-full gap-4 mt-8">
                    <ButtonBox
                      name='Close'
                      onClick={() => ClosePopUp()}
                    />
                  </div>
              </div>
          </div>
      </div>
      }
      </div>
    </div>
    </>
  )
}

export default ModalAreYouSureDeleteJob
