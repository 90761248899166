import React, { useState } from 'react'
// import { useSelector } from 'react-redux'
// import Navbar from '../components/Navbar'
// import AdminRoutes from '../routes/AdminRoutes'
import RouteAuth from '../routes/RouteAuth'
import RouteAdmin from '../routes/RouteAdmin'
import { useDispatch, useSelector } from 'react-redux'
import Navbar from '../components/Navbar'
import SideBar from '../components/SideBar'
import RouteMember from '../routes/RouteMember'
import RouteTechnician from '../routes/RouteTechnician'
import { setAuthToken } from '../APIs/api'
import { LoginUser } from '../redux/reduxStats/sliceAuth'

const AuthNavigation = () =>{
    return(
        <>
            {/* <BrowserRouter> */}
                <RouteAuth />
            {/* </BrowserRouter> */}
        </>
    )
}

const AdminNavigation = () => {
    return(
        <>  
        <div >
            <Navbar/>
            <div className='flex'>
                <SideBar/>
                <RouteAdmin /> 
            </div>
        </div>
        </>
    )
}

const MemberNavigation = () => {
    return(
        <>  
        <div >
            <Navbar/>
            <div className='flex'>
                <SideBar/>
                <RouteMember /> 
            </div>
        </div>
        </>
    )
}

const TechncianNavigation = () => {
    return(
        <>  
        <div >
            <Navbar/>
            <div className='flex'>
                <SideBar/>
                <RouteTechnician /> 
            </div>
        </div>
        </>
    )
}

const Navigation = () => {
    let account = useSelector(state=>state.authReducer.userProfile);
    return (
    <>
    {GetNavigation(account?.role)}
    {/* {AdminNavigation()} */}
    </>
    )
}

const GetNavigation = (accountRole = "login") => {
    if(accountRole == "console" || accountRole == "Admin"){
        return AdminNavigation()
    }else if(accountRole == "Member"){
        return MemberNavigation();
    }else if(accountRole == "Technician"){
        return TechncianNavigation();
    }else{
        return AuthNavigation()
    }
}

export default Navigation