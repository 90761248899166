import axios from 'axios';
import {BaseUrl, ServerURL} from '../config/constant';

const api = axios.create({
  baseURL: ServerURL,
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Generalized GET request function
export const get = async (url, config = {}) => {
  try {
    const response = await api.get(url, config);
    return response.data;
  } catch (error) {
    // Handle or throw error as needed
    throw error;
  }
};

// Generalized POST request function
export const post = async (url, data, config = {}) => {
  try {
    const response = await api.post(url, data, config);
    return response.data;
  } catch (error) {
    // Handle or throw error as needed
    throw error;
  }
};
