import React from 'react'
import {Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../screens/Dashboard/Dashboard'
import TeamPanel from '../screens/MemberManagement/TeamPanel'
import AddTeamMember from '../screens/MemberManagement/AddTeamMember'
import ModalScreenLock from '../modals/ModalScreenLock'
import ViewTeam from '../screens/MemberManagement/ViewTeam'
import ModalAreYouSure from '../modals/ModalAreYouSure'
import Profile from '../screens/Profile/Profile'
import ModalEditTeamInfo from '../modals/ModalEditTeamInfo'
import SalesPanel from '../screens/SaleManagement/SaleManagementPanel'
import BookSale from '../screens/SaleManagement/BookSale'
import JobUpdates from '../screens/SaleManagement/JobUpdates'
import CompletedJobs from '../screens/SaleManagement/CompletedJobs'
import ControlPanel from '../screens/ControlManagement/ControlManagementPanel'
import ViewCities from '../screens/ControlManagement/ViewCities'
import AwaitingJobs from '../screens/SaleManagement/AwaitingJobs'
import ModalEditJobDetails from '../modals/ModalEditJobDetails'
import ModalAreYouSureCity from '../modals/ModalAreYouSureCity'
import ModalEditCity from '../modals/ModalEditCity'
import ViewTeamSalary from '../screens/MemberManagement/ViewTeamSalary'
import ModalAreYouSureDeleteJob from '../modals/ModalAreYouSureDeleteJob'
import ModalEditJobStatus from '../modals/ModalEditJobStatus'
const RouteAuth = () => {
  return (
    <>
    <ModalScreenLock />
    <ModalAreYouSure />
    <ModalEditTeamInfo />
    <ModalEditJobDetails/>
    <ModalAreYouSureCity/>
    <ModalEditCity />
    <ModalAreYouSureDeleteJob/>
    <ModalEditJobStatus />
    <Routes>
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/team" element={<TeamPanel/>} />
        <Route path="/add" element={<AddTeamMember/>} />
        <Route path="/view" element={<ViewTeam/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/salespanel" element={<SalesPanel/>} />
        <Route path="/book" element={<BookSale/>} />
        <Route path="/jobupdates" element={<JobUpdates/>} />
        <Route path="/completedjobs" element={<CompletedJobs/>} />
        <Route path="/controlpanel" element={<ControlPanel/>} />
        <Route path="/cities" element={<ViewCities/>} />
        <Route path="/jobstatus" element={<AwaitingJobs/>} />
        <Route path="/salary" element={<ViewTeamSalary/>} />
        <Route path="*" element={<Navigate replace to={"/dashboard"} />} />
    </Routes> 
    </>
  )
}

export default RouteAuth