import React, { useEffect, useState } from 'react'
import TableBox from '../../components/TableBox'
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../APIs/api';
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader';
import { UpdateTeam } from '../../redux/reduxStats/sliceDataManagement';
import TableBoxTeamSalary from '../../components/TableBoxTeamSalary';

const ViewTeamSalary = () => {
  let accountId = useSelector(state=>state.authReducer.userProfile.uID);
  const dispatchItem = useDispatch();

  
  const tableHeader = [
    "S.No",
    "Full Name",
    "Completed Jobs",
    "Cancelled Jobs",
    "Salary/Sales",
    "Total Payable",
  ]

    // Get the current date
    const currentDate = new Date();

    // Get the current month (correcting for zero-index by adding 1)
    const currentMonth = currentDate.getMonth() + 1;

          // Format the date to "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear(); // Get the full year (2024)
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month, add 1 because it starts at 0, and pad with leading zero if necessary
    const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    return `${year}-${month}-${day}`; // Return the formatted string
  };

    const [isLoading, setIsLoading] = useState(false);
    const [teamSalary, setTeamSalary] = useState([]);
    const [salaryMonth, setSalaryMonth] = useState(currentMonth);
    const [salaryDate, setSalaryDate] = useState(formatDate(new Date()));
    const [globalMonth, setGlobalMonth] = useState("");


    useEffect(() =>{
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      setGlobalMonth(formattedDate);
      setSalaryMonth(currentMonth);
    },[])


    useEffect(() => {
      loadTeamSalary();
    }, [salaryMonth]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      setSalaryMonth(prevState => ({
        ...prevState,
        [name]: value
      }));

      const date = new Date(value);
      const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      setSalaryDate(formatDate(date));
      const monthNumber = date.getMonth() + 1;
      setGlobalMonth(formattedDate);
      setSalaryMonth(monthNumber);

    };


    const loadTeamSalary = async () => {
        setIsLoading(true);
        try{
        let salaryData = await get(`jobs/salary/${salaryMonth}`);
        if(salaryData.response != null){
            setTeamSalary(salaryData.response);
        }
        }catch(error){

        }
        setIsLoading(false);
    }
  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600'> 
      <div className="p-4 bg-purple-900 dark:bg-gray-800 flex">
        <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <p className=' text-xl text-white'><b>Team Salaries | Month: {globalMonth}</b></p>
            </div>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          onClick={() => loadTeamSalary()}
          isLoading={isLoading}
        />
        </div>
        
      </div>
      <div className='p-2'>
      <input  type='date' className='p-2' name='salaryDate' onChange={handleChange} value={salaryDate}/>
      </div>
      <TableBoxTeamSalary
        tableHeaderData={tableHeader}
        teamSalaryData={teamSalary}
      />
    </div>
    </>
  )
}

export default ViewTeamSalary
