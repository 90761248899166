import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AreYouSureModal, EditTeamInfoModal } from '../redux/reduxStats/sliceModal';
import { ClipLoader } from 'react-spinners';
import TableHeaderBox from './TableHeaderBox';

const TableBoxTeamSalary = ({tableHeaderData = [],teamSalaryData = []}) => {
    let count = 0;
  return (
    <>
        
<div className="container  px-4 mx-auto sm:px-8">
    <div className="py-2">
            <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                    <table className="min-w-full leading-normal">
                        <TableHeaderBox tableHeader={tableHeaderData} />
                        <tbody>
                            {teamSalaryData?.map((item,index) => (
                                count++,
                                <tr key={index}>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <div className="flex items-center">
                                            <div className="">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {count}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <div className="flex items-center">
                                            <div className="">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {item.fullName}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {item.Completed}
                                        </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {item.Cancelled}
                                        </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {item.commission}
                                        </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                            {item.salary}
                                        </p>
                                    </td>

                                    {/* <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <button onClick={() => OpenEditPopUp(item)} className="text-indigo-600 mx-1 hover:text-indigo-900">
                                            Edit
                                        </button>
                                        <button onClick={() => OpenPopUp(item)} className="text-red-600 mx-1 hover:text-red-900">
                                            Delete
                                        </button>
                                    </td> */}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                   
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default TableBoxTeamSalary
