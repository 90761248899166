import React from 'react'
import {Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../screens/Dashboard/Dashboard'
import ModalScreenLock from '../modals/ModalScreenLock'
import ModalAreYouSure from '../modals/ModalAreYouSure'
import Profile from '../screens/Profile/Profile'
import SaleManagement from '../screens/SaleManagement/SaleManagementPanel'
import AwaitingJobsAgent from '../screens/SaleManagement/AwaitingJobsAgent'
import ModalEditJobStatus from '../modals/ModalEditJobStatus'

const RouteTechnician = () => {
  return (
    <>
    <ModalScreenLock />
    <ModalEditJobStatus />
    <Routes>
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/salespanel" element={<SaleManagement/>} />
        <Route path="/agentjobs" element={<AwaitingJobsAgent/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="*" element={<Navigate replace to={"/dashboard"} />} />
    </Routes> 
    </>
  )
}

export default RouteTechnician