import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  let userProfile = useSelector(state=>state.authReducer.userProfile);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const sideBarTabs_Normal = [
    {tabname: "Dashboard", link: "dashboard"},
    {tabname: "Sales Management", link: "salespanel"},
    {tabname: "Profile", link: "profile"},
]

const sideBarTabs_Admin = [
    {tabname: "Dashboard", link: "dashboard"},
    {tabname: "Team Management", link: "team"},
    {tabname: "Sales Management", link: "salespanel"},
    {tabname: "Control Management", link: "controlpanel"},
    {tabname: "Profile", link: "profile"},
]

  const navigateToScreen = (value) => {
          
    navigate(`/${value}`);
  }
  const Logout = () =>{
    window.localStorage.clear();
  }

  return (
    <>
    <nav className="relative flex flex-wrap items-center justify-between px-2 bg-purple-800 border-b-2 border-indigo-400 dark:bg-gray-900">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <div className="w-full flex items-center">
            <p className=" text-lime-400 dark:text-white" ><b>Welcome</b></p>
            <p className=" ml-1 text-white dark:text-lime-400" ><b>{userProfile.firstName} </b></p>
            <span className="ml-2 px-1 py-1 text-sm rounded text-white  bg-purple-600 font-medium">
              {userProfile.role}
            </span>
            
          </div>
            <button
              className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
               <span className="bg-purple-500 relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="bg-purple-500 relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
                <span className="bg-purple-500 relative my-[6px] block h-[2px] w-[30px] bg-body-color dark:bg-white"></span>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
           <div className="hidden justify-end pr-16 ml-auto sm:flex lg:pr-0">
              <a
              onClick={() => Logout()}
                href=""
                className="rounded-md bg-primary px-7 py-3 text-base font-medium text-white hover:bg-primary/90"
              >
                Logout
              </a>
            </div>
            <ul className={
              "flex flex-col lg:flex-row list-none lg:ml-auto sm:hidden"
            }>
            {
              userProfile?.role == "console" || userProfile?.role == "Admin" ? sideBarTabs_Admin.map((tabs, index) => (
              <li className="nav-item" key={index}>
              <button>
              <a onClick={() => navigateToScreen(tabs.link)}
                className="text-purple-300 hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
              >
                <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="">{tabs.tabname}</span>
              </a>
              </button>
            </li>
                  // <a key={index} onClick={() => navigateToScreen(tabs.link)} className="text-purple-300 hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg " href="#">
                  //     <p><b>*</b></p>
                  //     <span className="mx-4 text-lg font-normal">
                  //         {tabs.tabname}
                  //     </span>
                  //     <span className="flex-grow text-right">
                  //     </span>
                  // </a>
              )) 
              :
              sideBarTabs_Normal.map((tabs, index) => (
                <li className="nav-item" key={index}>
                <button>
                <a onClick={() => navigateToScreen(tabs.link)}
                  className=" hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                >
                  <i className="fab fa-facebook-square text-lg leading-lg text-white opacity-75"></i><span className="">{tabs.tabname}</span>
                </a>
                </button>
              </li>
              ))
            }
              <li className="nav-item">
                <button>
                <a onClick={() => Logout()}
                  className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 transition-colors dark:hover:text-white dark:hover:bg-gray-600 duration-200  text-gray-600 dark:text-gray-400 rounded-lg "
                  href=""
                >
                  <i className="fab fa-twitter text-lg leading-lg text-white opacity-75"></i><span className="">Logout</span>
                </a>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>

  );
};

export default Navbar;

const ListItem = ({ children, NavLink }) => {
  return (
    <>
      <li>
        <a
          href={NavLink}
          className="flex py-2 text-base font-medium text-body-color hover:text-dark dark:text-dark-6 dark:hover:text-white lg:ml-12 lg:inline-flex"
        >
          {children}
        </a>
      </li>
    </>
  );
};
