import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import ButtonBox from '../components/ButtonBox';
import { EditTeamInfoModal, UpdateSelectedDataModal } from '../redux/reduxStats/sliceModal';
import { get, post } from '../APIs/api';
import { RemoveMember, UpdateStatus, UpdateTeamElement } from '../redux/reduxStats/sliceDataManagement';
import InputBox from '../components/InputBox';
import DatePickerBox from '../components/DatePickerBox';
import DropDownBox from '../components/DropDownBox';
import ButtonBoxWithoutLoader from '../components/ButtonBoxWithoutLoader';
import * as Yup from 'yup';

const ModalEditTeamInfo = () => {
  let modalPopUp = useSelector(state=>state.modalReducer.customModal.editTeamMember);
  
  let selectedData = useSelector(state=>state.modalReducer.selectedData);

  let accountId = useSelector(state=>state.authReducer.userProfile.uID);
  const [roles, setRoles] = useState(null);
  useEffect(() => {
      loadDropDown(accountId);
  }, [accountId]);

  const loadDropDown = async (uID) => {
    try{
        let accountRoles = await get(`auth/roles/${uID}`);
        if(accountRoles.response != null){
            setRoles(accountRoles.response);
        }
      }catch(error){
        setRoles(null);
        console.log(error);
      }
  }

  const [userProfile, setUserProfile] = useState(selectedData);
  const [password, setPassword] = useState("");
  useEffect(()=>{
    setUserProfile(selectedData)
  },[selectedData])

  const [isDisabling, setIsDisabling] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatchItem = useDispatch();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'First name must be at least 2 characters long')
      .max(50, 'First name must be less than 50 characters long'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Last name must be at least 2 characters long')
      .max(50, 'Last name must be less than 50 characters long'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^[0-9]{10,}$/, "Invalid phone number, must be at least 10 digits"),
    dob: Yup.date()
      .required('Date of birth is required')
      .max(new Date(), 'Date of birth cannot be in the future'),
    accountName: Yup.string()
      .required('Account type is required')
      .oneOf(['Member', 'Technician', 'Admin'], 'Invalid account type'),
    salary: Yup.number()
      .required('Amount is required')
      .min(1000, 'Amount must be at least PKR 1000')
      .max(10000, 'Amount must not exceed PKR 10000')
  });
  

  const ClosePopUp = () => {
    dispatchItem(
      EditTeamInfoModal(false)
    )
  }

  const setAccountStatus = async (value) => {
    setIsDisabling(true);

    let userDataBody = {
      uID: selectedData.uID,
      status: value
    }
    try{
      const userData = await post(`account/status`,userDataBody);
      if(userData.response != null){
        setUserProfile(prevState => ({
          ...prevState,
          status: userData.response
        }));
        dispatchItem(
          UpdateStatus(userDataBody.uID, userData.response),
          UpdateSelectedDataModal(userProfile)
        )
      }

    }catch(error){
      console.log(error);
    }
    setIsDisabling(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProfile(prevState => ({
      ...prevState,
      [name]: value
    }));
    
    if(name == "password"){
      setPassword(e.target.value)
    }
  };

  const handleDropChange = (event) => {
    const { options, selectedIndex } = event.target;
    const name = options[selectedIndex].text;
    const value = options[selectedIndex].value;
    // Now calling onChange prop with both selected value and name
    setUserProfile(prevState => ({
      ...prevState,
      accountType: value,
      accountName: name,
    }))
  };


  const UpdateUser = async () => {
    setIsLoading(true);
    try{
      await validationSchema.validate(userProfile, { abortEarly: true });
      const userData = await post(`account/update`,userProfile);
      alert(userData.message);
      if(userData.response != null){
        dispatchItem(
          UpdateTeamElement(userProfile),
          UpdateSelectedDataModal(userProfile)
        )
      }
    }catch(error){
      setIsLoading(false);
    }

    setUserProfile(prevState => ({
      ...prevState,
      password: ""
    }));
    
    setPassword("")
    setIsLoading(false);
  }

  return (
    <>
    <div
      className={`
        fixed inset-0 z-10 flex justify-center items-center transition-colors
        ${modalPopUp ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`
           rounded-xl p-6 transition-all
          ${modalPopUp ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
        <section className="w-full bg-purple-800 dark:bg-slate-600">
          
          <div className="p-4 bg-purple-900 dark:bg-gray-800 ">
          <svg onClick={() => ClosePopUp()} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="w-6 h-6 ml-auto hover:text-white text-gray-700" viewBox="0 0 1792 1792">
                  <path d="M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z">
                  </path>
              </svg>
              <div className="w-full">
                  <div className="inline-flex items-center justify-center space-x-4 w-full">
                  <p className=' text-3xl text-white'><b>Update Employee Info</b></p>
                  </div>
              </div>
          </div>

          <div className="space-y-2">
            <div className='py-4 px-8 w-full items-center'>
                <div className='flex justify-between items-center mb-4'>
                  <p className='text-white'><b>Profile Details: {userProfile?.fullName} ({userProfile?.status})</b></p>
                  {
                  isDisabling ? 
                  <button className='w-1/4'>
                    <div className='bg-gray-500 p-2 rounded'>
                      <p className='text-gray-900'><b>Saving</b></p>
                    </div>
                  </button> 
                  :
                  userProfile?.status == "Active" ?
                  <button className='w-1/4' onClick={() => setAccountStatus(0)}>
                    <div className='bg-red-500 p-2 rounded'>
                      <p className='text-red-900'><b>Disable Account</b></p>
                    </div>
                  </button>
                  :
                  <button className='w-1/4' onClick={() => setAccountStatus(1)}>
                    <div className='bg-green-500 p-2 rounded'>
                      <p className='text-green-900'><b>Enable Account</b></p>
                    </div>
                  </button>
                  }
                </div>
                <div className='flex justify-around w-full'>
                  <InputBox 
                    placeholder={"First Name"} 
                    name={"firstName"}
                    label={"First Name"} 
                    type={"text"}
                    value={userProfile?.firstName}
                    onChange={handleChange}
                  />
                  <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Last Name"} 
                    name={"lastName"}
                    label={"Last Name"} 
                    type={"text"}
                    value={userProfile?.lastName}
                    onChange={handleChange}
                    
                  />
                </div>
                <div className='flex justify-around w-full'>
                <InputBox 
                    placeholder={"Email"} 
                    name={"email"}
                    label={"Email"} 
                    type={"text"}
                    
                    value={userProfile?.email}
                    onChange={handleChange}
                  />
                   <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Phone Number"} 
                    name={"phoneNumber"}
                    label={"Phone Number"} 
                    type={"text"}
                    
                    value={userProfile?.phoneNumber}
                    onChange={handleChange}
                  />
                </div>
                <div className='flex justify-around w-full'>
                <DatePickerBox
                    name={"dob"}
                    label={"Date of birth"}
                    value={userProfile?.dob}
                    onChange={handleChange}
                />
                <div className='mx-2'></div>
                  <InputBox 
                    placeholder={"Salary/Sales"} 
                    name={"salary"}
                    label={"Salary Amount"} 
                    type={"number"}
                    onChange={handleChange}
                    value={userProfile?.salary}
                  />
                </div>
                <div className='flex justify-around w-full'>
                <DropDownBox 
                   onChange={handleDropChange}
                   dropDownName={"Account Type"}
                   value={userProfile?.accountType}
                   dropDownData={roles}
                   idField='rID'
                 />
                  <div className='w-full'></div>

                </div>
                
            </div>
            <div className='flex justify-around w-full'>
            <div className='w-3/4'></div>
                <ButtonBoxWithoutLoader
                  name='Update'
                  loadingText='Updating'
                  isLoading={isLoading}
                  onClick={() => UpdateUser()}
                />
                  <div className='w-3/4'></div>
                  </div>
            <hr className='bg-purple-100'/>
                 
            </div>
        </section>
      
      </div>
    </div>
    </>
  )
}

export default ModalEditTeamInfo
