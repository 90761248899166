import React, { useEffect, useState } from 'react'
import StatsBox from '../../components/StatsBox'
import TableBox from '../../components/TableBox'
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../APIs/api';
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader';
import { InitiateJobs, UpdateTeam } from '../../redux/reduxStats/sliceDataManagement';
import TableJobsBox from '../../components/TableJobsBox';
import AwaitingJobCard from '../../components/AwaitingJobCard';
import SelectTabBtn from '../../components/SelectTabBtn';

const AwaitingJobs = () => {

  let userProfile = useSelector(state=>state.authReducer.userProfile);
  let jobList = useSelector(state=>state.dataManagementReducer.jobs);

  const dispatchItem = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [jobListData, setJobListData] = useState(null);

    const [pendingJobs, setPendingJobs] = useState([]);
    const [inProgressJob, setInProgressJob] = useState([]);
    const [completedJobs, setCompletedJobs] = useState([]);
    const [cancelledJobs, setCancelledJobs] = useState([]);
    const [agents, setAgents] = useState([]);


    // Get the current date
    const currentDate = new Date();

    // Get the current month (correcting for zero-index by adding 1)
    const currentMonth = currentDate.getMonth() + 1;
          // Format the date to "YYYY-MM-DD"
    const formatDate = (date) => {
      const year = date.getFullYear(); // Get the full year (2024)
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get the month, add 1 because it starts at 0, and pad with leading zero if necessary
      const day = date.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
      return `${year}-${month}-${day}`; // Return the formatted string
    };

    const [showStatusJobCount, setShowStatusJobCount] = useState(0);
    const [globalMonth, setGlobalMonth] = useState("");
    const [jobMonth, setJobMonth] = useState(currentMonth);
    const [jobDate, setJobDate] = useState(formatDate(new Date()));

    const [selectedTab, setSelectedTab] = useState({tabname: "Pending", title: "Awaiting Jobs - Approval"},);

    useEffect(() =>{
      const date = new Date();
      const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      setGlobalMonth(formattedDate);
      setJobMonth(currentMonth);
    },[])

    useEffect(() => {
      loadJobs(userProfile.uID, jobMonth);
    },[jobMonth])

    useEffect(() => {
      setJobListData(jobList);
    },[jobList])


    const handleChange = (e) => {
      const { name, value } = e.target;
      setJobMonth(prevState => ({
        ...prevState,
        [name]: value
      }));

      const date = new Date(value);
      const formattedDate = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
      setJobDate(formatDate(date));
      const monthNumber = date.getMonth() + 1;
      setGlobalMonth(formattedDate);
      setJobMonth(monthNumber);

    };

    const loadJobs = async (uID, month) => {
      setIsLoading(true);
      let jobs = {};
      try {
        // Create two promises for pending and in-progress jobs
        const pendingJobsPromise = get(`jobs/pending/${uID}/Pending/${month}`);
        const inProgressJobsPromise = get(`jobs/pending/${uID}/In-Progress/${month}`);
        const completedJobsPromise = get(`jobs/pending/${uID}/Completed/${month}`);
        const cancelledJobsPromise = get(`jobs/pending/${uID}/Cancelled/${month}`);

        // const agentPromise = get(`account/agents`);
    
        // Use Promise.all to wait for both promises to resolve
        const [pendingJobData, inProgressJobData, completedJobData, cancelledJobData] = await Promise.all([pendingJobsPromise, inProgressJobsPromise, completedJobsPromise, cancelledJobsPromise]);
    
        // Handle the response for pending jobs
        // if (agentListData.response != null) {
        //   console.log(agentListData);
        //   setAgents(agentListData.response);
        // } else {
        //   setAgents([]);
        // }
        
        // Handle the response for pending jobs
        if (pendingJobData.response != null) {
          setPendingJobs(pendingJobData.response);
        } else {
          setPendingJobs([]);
        }
    
        // Handle the response for in-progress jobs
        if (inProgressJobData.response != null) {
          setInProgressJob(inProgressJobData.response);
        } else {
          setInProgressJob([]);
        }

         // Handle the response for completed jobs
        if (completedJobData.response != null) {
          setCompletedJobs(completedJobData.response);
        } else {
          setCompletedJobs([]);
        }

         // Handle the response for cancelled jobs
         if (cancelledJobData.response != null) {
          setCancelledJobs(cancelledJobData.response);
        } else {
          setCancelledJobs([]);
        }

        jobs = {
          pendingJobs: pendingJobData.response,
          inProgressJobs: inProgressJobData.response,
          completedJobs: completedJobData.response,
          cancelledJobs: cancelledJobData.response,
        }

      } catch (error) {

        jobs = {
          pendingJobs: [],
          inProgressJobs: [],
          completedJobs: [],
          cancelledJobs: [],
        }
        // Handle any errors that occurred during the fetch
        console.error("Error loading jobs", error);
        // Potentially set error state here if you have one
      }

      dispatchItem(
        InitiateJobs(
          jobs
        ),
      )
      setJobCounts(jobs?.pendingJobs?.length);
      setIsLoading(false);
    };
    
    useEffect(() => {
      setJobCounts(selectedTab.tabname);
    },[jobList])


  const smallTabs = [
    {tabname: "Pending", title: "Awaiting Jobs - Approval"},
    {tabname: "In-Progress", title: "Jobs In-Progress"},
    {tabname: "Completed", title: "Completed Jobs"},
    {tabname: "Cancelled", title: "Cancelled Jobs"}
  ]

  const selectTab = (value) => {
    setSelectedTab(value)
    setJobCounts(value.tabname);
  }

  useEffect(() => {
    console.log("--->month ",jobMonth);
  },[jobMonth])

  const setJobCounts = (value) =>{
    if(value == "Pending"){
      setShowStatusJobCount(jobList.pendingJobs?.length);
    }else if(value == "In-Progress"){
      setShowStatusJobCount(jobList.inProgressJobs?.length);
    }else if(value == "Completed"){
      setShowStatusJobCount(jobList.completedJobs?.length);
    }else if(value == "Cancelled"){
      setShowStatusJobCount(jobList.cancelledJobs?.length);
    }
  }
  return (
    <>
    <div className='w-full bg-purple-700 dark:bg-slate-600'> 
      <div className="p-4 bg-purple-900 dark:bg-gray-800 flex">
        <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className=" items-center">
              <p className=' text-3xl text-white'><b>{selectedTab.title}</b></p>
              <p className=' text-xl text-white'><b>Jobs: {showStatusJobCount} | Month: {globalMonth}</b></p>
            </div>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          onClick={() => loadJobs(userProfile.uID, jobMonth)}
          isLoading={isLoading}
        />
        </div>
      </div>
      <div className='w-full my-2 flex px-4'>
      <div className='p-2'>
      <input  type='date' className='p-2' name='jobDate' onChange={handleChange} value = {jobDate}/>
      </div>
        <div className='m-auto'></div>
        <div>
          {smallTabs.map((item, index) => (
            <SelectTabBtn onClick={() => selectTab(item)} tabname={item.tabname} selectedTabName={selectedTab.tabname} />
          ))}
        </div>
      </div>
      {buildList(selectedTab.tabname, userProfile.role, {pendingJobs:jobListData?.pendingJobs, 
        inProgressJob:jobListData?.inProgressJobs, completedJobs: jobListData?.completedJobs, 
        cancelledJobs: jobListData?.cancelledJobs})}
      
    </div>
    </>
  )
}

const buildList = (selectedTabName, role, jobsArrays) => {
  if(selectedTabName == "Pending"){
    return(
      <div className='w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        {
          jobsArrays.pendingJobs?.map((item, index) => (
            <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
          ))
        }
      </div>
    )
  }else if(selectedTabName == "In-Progress"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.inProgressJob?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }else if(selectedTabName == "Completed"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.completedJobs?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }else if(selectedTabName == "Cancelled"){
    return(
      <div className='w-full grid grid-cols-4 p-2 gap-2'>
      {
        jobsArrays.cancelledJobs?.map((item, index) => (
          <AwaitingJobCard leadDetails={item} key={index} accountRole={role} />
        ))
      }
    </div>
    )
  }
}

export default AwaitingJobs
