import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AreYouSureCityModal, AreYouSureModal, EditCityModal, EditTeamInfoModal } from '../redux/reduxStats/sliceModal';
import { ClipLoader } from 'react-spinners';
import TableHeaderBox from './TableHeaderBox';

const TableCityBox = ({tableHeaderData = [],cityData = []}) => {
    let modalPopUp = useSelector(state=>state.modalReducer.customModal.editTeamMember);
    let count = 0;
    const dispatchItem = useDispatch();
  
    const OpenPopUp = (item) => {
      dispatchItem(
        AreYouSureCityModal(true, item)
      )
    }

    const OpenEditPopUp = (item) => {
        dispatchItem(
          EditCityModal(true, item)
        )
      }
  return (
    <>
        
<div className="container  px-4 mx-auto sm:px-8">
    <div className="py-2">
            <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                <div className="inline-block min-w-full overflow-hidden rounded-lg shadow">
                    <table className="min-w-full leading-normal">
                        <TableHeaderBox tableHeader={tableHeaderData} />
                        <tbody>
                            {cityData?.map((item,index) => (
                                count++,
                                <tr key={index}>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <div className="flex items-center">
                                            <div className="">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {count}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <div className="flex items-center">
                                            <div className="">
                                                <p className="text-gray-900 whitespace-no-wrap">
                                                    {item.name}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                    {item.status == "Active" ?
                                        <span className="relative inline-block px-3 py-1 font-semibold leading-tight text-green-900">
                                            <span aria-hidden="true" className="absolute inset-0 bg-green-200 rounded-full opacity-50">
                                            </span>
                                            <span className="relative">
                                                {item.status}
                                            </span>
      
                                        </span>
                                        :<span className="relative inline-block px-3 py-1 font-semibold leading-tight text-red-900">
                                        <span aria-hidden="true" className="absolute inset-0 bg-red-200 rounded-full opacity-50">
                                        </span>
                                        <span className="relative">
                                            {item.status}
                                        </span>
  
                                    </span>
                                    }
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <button onClick={() => OpenEditPopUp(item)} className="text-indigo-600 mx-1 hover:text-indigo-900">
                                            Edit
                                        </button>
                                        <button onClick={() => OpenPopUp(item)} className="text-red-600 mx-1 hover:text-red-900">
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="flex flex-col items-center px-5 py-5 bg-white xs:flex-row xs:justify-between">
                        <div className="flex items-center">
                            <button type="button" className="w-full p-4 text-base text-gray-600 bg-white border rounded-l-xl hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-indigo-500 bg-white border-t border-b hover:bg-gray-100 ">
                                1
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                2
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border-t border-b hover:bg-gray-100">
                                3
                            </button>
                            <button type="button" className="w-full px-4 py-2 text-base text-gray-600 bg-white border hover:bg-gray-100">
                                4
                            </button>
                            <button type="button" className="w-full p-4 text-base text-gray-600 bg-white border-t border-b border-r rounded-r-xl hover:bg-gray-100">
                                <svg width="9" fill="currentColor" height="8" className="" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                    </path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </>
  )
}

export default TableCityBox
