import React from 'react'
import StatusCard from './StatusCard'
import ButtonBoxWithoutLoader from './ButtonBoxWithoutLoader'
import DropDownBox from './DropDownBox';
import { useDispatch } from 'react-redux';
import { AreYouSureCityModal, AreYouSureDeleteJobModal, EditJobDetailModal, EditJobStatusModal } from '../redux/reduxStats/sliceModal';


const  AwaitingJobCard = ({leadDetails = null, accountRole}) => {
    const dispatchItem = useDispatch();
    const openJobEditModal = (element) => {
        if(accountRole == "Technician"){
            dispatchItem(
                EditJobStatusModal(true,element)
            )
            return;
        }
        dispatchItem(
            EditJobDetailModal(true,element)
        )
    }

    const OpenJobStatusModal = (element) =>{
        if(accountRole == "Admin" || accountRole == "console"){
            dispatchItem(
                EditJobStatusModal(true,element)
            )
            return;
        }
    }

    const DeleteJobModal = (element) => {
        dispatchItem(
            AreYouSureDeleteJobModal(true,element)
        )
    }
    const CheckIsEditTimeOver = (timeString) => {
        const pastTime = new Date(timeString);
        const currentTime = new Date();
        
        // Calculate the difference in milliseconds
        const differenceInMilliseconds = currentTime - pastTime;
        
        // Convert milliseconds to seconds (1 second = 1000 milliseconds)
        const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
        return differenceInSeconds < 300 ? true : false;
      };

                // Format the date to "YYYY-MM-DD"
  const formatDate = (date) => {
    const newDate = new Date(date);
    const year = newDate.getFullYear(); // Get the full year (2024)
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0'); // Get the month, add 1 because it starts at 0, and pad with leading zero if necessary
    const day = newDate.getDate().toString().padStart(2, '0'); // Get the day and pad with leading zero if necessary
    return `${year}-${month}-${day}`; // Return the formatted string
  };
      

  return (
    <>      
<div className="w-full p-3 dark:border dark:border-white bg-purple-500 shadow-lg rounded-2xl dark:bg-gray-700">
    <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
            <div className="flex flex-col">
                <span className="ml-2 font-bold text-white text-md dark:text-white">
                   {leadDetails.jobType}
                </span>
                <span className="ml-2 text-sm text-white dark:text-white">
                    {leadDetails?.customerName}
                </span>
                
            </div>
        </div>
        { (accountRole == "Admin" || accountRole == "console") && leadDetails.jobStatus != "Completed" && leadDetails.jobStatus != "Cancelled"?
            <button onClick={() => DeleteJobModal(leadDetails)} className='text-sm text-white bg-red-500 px-2 rounded'>Delete</button>
            :null
        }
        {(CheckIsEditTimeOver(leadDetails.createdAt) == true && accountRole == "Member" && leadDetails.jobStatus == "Pending") || accountRole == "Technician" && (leadDetails.jobStatus == "Pending" || leadDetails.jobStatus == "In-Progress")?
            <button onClick={() => openJobEditModal(leadDetails)} className='text-sm text-white bg-green-500 px-2 rounded'>Edit</button>
        : null}
        {accountRole == "Admin" || accountRole == "console" ? leadDetails.jobStatus != "Completed" && leadDetails.jobStatus != "Cancelled"? <button onClick={() => openJobEditModal(leadDetails)} className='text-sm text-white bg-green-500 px-2 rounded'>Edit</button>
        :null : null}
        
    </div>
    <div className="flex items-center justify-between mb-4 space-x-12">
        <StatusCard statusCode={leadDetails.jobStatus} onClick={() => OpenJobStatusModal(leadDetails)}/>
        <span className="flex items-center px-2 py-1 text-xs font-semibold text-green-500 rounded-md bg-green-50">
            City: {leadDetails.city}
        </span>
    </div>
    <div className="flex items-center justify-start my-4 space-x-4"> 
        <span className="flex items-center px-2 py-1 text-xs font-semibold text-yellow-600 bg-yellow-200 rounded-md">
            {leadDetails.currency + ": "+leadDetails.jobCost} | QTY: {leadDetails.qty} | Posted: {formatDate(leadDetails.createdAt)}
        </span>
    </div>
    <div class="my-1">
        <p className='text-sm text-white'>Comments</p>
        <div className='bg-purple-700 dark:bg-white rounded p-1'>
        {leadDetails.customerComments == ""? 
        <p className='text-sm bold text-white dark:text-black'><i>No Comments</i></p>
        :
        <p className='text-sm bold text-white dark:text-black'>{leadDetails.customerComments}</p>
        }
        
       </div>
       
    </div>
        <div class="my-1">
        <p className='text-sm text-white'>Address</p>
        <p className='text-sm font-bold text-white'>{leadDetails.address}</p>
       
    </div>
    <div class="my-1">
        <p className='text-sm text-white'>Phone</p>
        <p className='text-sm font-bold text-white'>{leadDetails.phoneNumber}</p>
       
    </div>
    {leadDetails.bookedByName != null && accountRole != "Technician" ?
        <div class="mt-4">
        <p className='text-sm text-white'>Booked By: {leadDetails.bookedByName}</p>
        </div>
    :null}
    {accountRole == "Admin" || accountRole == "console" ? 
            <span className="px-1 py-1 text-sm rounded text-white  bg-purple-600 font-medium">
              Agent: {leadDetails?.agent}
            </span>
    :null}
    <div className='flex justify-between'>
    <span className="flex items-center px-2 mt-4 text-xs font-semibold text-yellow-600 bg-yellow-100 rounded-md w-36">
        DUE DATE : {leadDetails.bookingDate}
    </span>
    <span className="flex items-center px-2  mt-4 text-xs font-semibold text-yellow-600 bg-yellow-100 rounded-md w-16">
        Time : {leadDetails.bookingTime}
    </span>
    </div>
</div>

    </>
  )
}

export default AwaitingJobCard
