import React from 'react'
import BigButtonBox from '../../components/BigButtonBox'

const TeamPanel = () => {

  const options = [
    {buttonName:'Add Team Member', link: "add"},
    {buttonName:'View Team Members', link: "view"},
    {buttonName:'Generate Team Salary', link: "salary"},
  ]
  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600 h-screen'> 
      <div className='p-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 w-full justify-around'>
        {options.map((items, index) => (
          <BigButtonBox key={index} buttonName = {items.buttonName} screenName={items.link} />
        ))}
      </div>
    </div>
    </>
  )
}

export default TeamPanel
