import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customModal: {
        screenLock: false,
        areYouSure: false,
        editTeamMember: false,
        editJobDetails: false,
        editJobStatus: false,
        areYouSureCity: false,
        editCity: false,
        areYouSureDeleteJob: false,
    },
    selectedData: null
}

export const sliceModal = createSlice({
    name: 'modalStats',
    initialState,
    reducers: {
        ScreenLockModal:{
            reducer(state, action){
                state.customModal.screenLock = action.payload
            },
            prepare(isOpen) {
                return{
                    payload: isOpen
                }
            }
        },
        AreYouSureModal:{
            reducer(state, action){
                state.customModal.areYouSure = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        AreYouSureCityModal:{
            reducer(state, action){
                state.customModal.areYouSureCity = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        EditTeamInfoModal:{
            reducer(state, action){
                state.customModal.editTeamMember = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        EditCityModal:{
            reducer(state, action){
                state.customModal.editCity = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        EditJobDetailModal:{
            reducer(state, action){
                state.customModal.editJobDetails = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        UpdateSelectedDataModal:{
            reducer(state, action){
                state.selectedData = action.payload
            },
            prepare(selectedData) {
                return{
                    payload: selectedData
                }
            }
        },
        EditJobStatusModal:{
            reducer(state, action){
                state.customModal.editJobStatus = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        },
        AreYouSureDeleteJobModal:{
            reducer(state, action){
                state.customModal.areYouSureDeleteJob = action.payload.isOpen;
                state.selectedData = action.payload.selectedData
            },
            prepare(isOpen, selectedData) {
                return{
                    payload: {
                        isOpen: isOpen,
                        selectedData: selectedData
                    }
                }
            }
        }

    }
});

export const { ScreenLockModal, AreYouSureModal, EditTeamInfoModal, UpdateSelectedDataModal, EditJobDetailModal, EditJobStatusModal, AreYouSureCityModal, EditCityModal, AreYouSureDeleteJobModal } = sliceModal.actions;

export default sliceModal.reducer;