import React from 'react'
import { useSelector } from 'react-redux';

const ModalScreenLock = () => {
  let modalPopUp = useSelector(state=>state.modalReducer.customModal.screenLock);
  return (
    <>
    <div
      className={`
        fixed inset-0 flex justify-center items-center transition-colors
        ${modalPopUp ? "visible bg-black/20" : "invisible"}
      `}
    >
      {/* modal */}
      <div
        // onClick={(e) => e.stopPropagation()}
        className={`
           rounded-xl p-6 transition-all
          ${modalPopUp ? "scale-100 opacity-100" : "scale-125 opacity-0"}
        `}
      >
      </div>
    </div>
    </>
  )
}

export default ModalScreenLock
