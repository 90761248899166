import React from 'react'
import BigButtonBox from '../../components/BigButtonBox'
import { useSelector } from 'react-redux';

const BookSales = () => {
  let accountRole = useSelector(state=>state.authReducer.userProfile.role);
  const adminButtons = [
    {buttonName: "Book Job", screenName: "book"},
    {buttonName: "Job Status", screenName: "jobstatus"},
  ]

  const memberButtons = [
    {buttonName: "Book Job", screenName: "book"},
    {buttonName: "Job Status", screenName: "jobstatus"},
  ]

  const technicanButtons = [
    {buttonName: "Job Status", screenName: "agentjobs"},
  ]
  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600 h-screen'> 
      <div className='p-5 grid grid-cols-3 gap-4 w-full justify-around'>
          {
            accountRole == "console" || accountRole == "Admin" ? adminButtons.map((item, index) => (
              <BigButtonBox buttonName = {item.buttonName} key={index} screenName={item.screenName} />
            )) 
            : 
            accountRole == "Member" ? 
            memberButtons.map((item, index) => (
              <BigButtonBox buttonName = {item.buttonName} key={index} screenName={item.screenName} />
            ))     
            :
            technicanButtons.map((item, index) => (
              <BigButtonBox buttonName = {item.buttonName} key={index} screenName={item.screenName} />
            )) 
          }
      </div>
    </div>
    </>
  )

}

export default BookSales
