import React, { useEffect, useState } from 'react'
import StatsBox from '../../components/StatsBox'
import TableBox from '../../components/TableBox'
import { useDispatch, useSelector } from 'react-redux';
import { get } from '../../APIs/api';
import ButtonBoxWithoutLoader from '../../components/ButtonBoxWithoutLoader';
import { UpdateTeam } from '../../redux/reduxStats/sliceDataManagement';

const JobUpdates = () => {
  let accountId = useSelector(state=>state.authReducer.userProfile.uID);
  let teamList = useSelector(state=>state.dataManagementReducer.teams);
  const dispatchItem = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      if(teamList == null){
        loadTeamList(accountId);
      }
    }, [accountId]);

    const loadTeamList = async (uID) => {
        setIsLoading(true);
        try{
        let teamData = await get(`account/team/${uID}`);
        if(teamData.response != null){
            dispatchItem(
              UpdateTeam(teamData.response)
            )
        }
        }catch(error){
          
        }
        setIsLoading(false);
    }
  return (
    <>
    <div className='w-full bg-purple-900 dark:bg-slate-600'> 
      <div className="p-4 bg-purple-900 dark:bg-gray-800 flex">
        <div className="max-w-sm mx-auto md:w-full md:mx-0">
            <div className="inline-flex items-center space-x-4">
              <p className=' text-3xl text-white'><b>Employees</b></p>
            </div>
        </div>
        <div className='ml-auto'>
        <ButtonBoxWithoutLoader
          name='Refresh'
          loadingText='Loading'
          onClick={() => loadTeamList(accountId)}
          isLoading={isLoading}
        />
        </div>
      </div>
      <TableBox
        teamData={teamList}
      />
    </div>
    </>
  )
}

export default JobUpdates
