import React from 'react'
import {Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../screens/Dashboard/Dashboard'
import TeamPanel from '../screens/MemberManagement/TeamPanel'
import AddTeamMember from '../screens/MemberManagement/AddTeamMember'
import ModalScreenLock from '../modals/ModalScreenLock'
import ViewTeam from '../screens/MemberManagement/ViewTeam'
import ModalAreYouSure from '../modals/ModalAreYouSure'
import Profile from '../screens/Profile/Profile'
import ModalEditTeamInfo from '../modals/ModalEditTeamInfo'
import BookSale from '../screens/SaleManagement/BookSale'
import SaleManagement from '../screens/SaleManagement/SaleManagementPanel'
import JobUpdates from '../screens/SaleManagement/JobUpdates'
import CompletedJobs from '../screens/SaleManagement/CompletedJobs'
import AwaitingJobs from '../screens/SaleManagement/AwaitingJobs'
import ModalEditJobDetails from '../modals/ModalEditJobDetails'

const RouteMember = () => {
  return (
    <>
    <ModalScreenLock />
    <ModalAreYouSure />
    <ModalEditJobDetails/>
    <Routes>
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/salespanel" element={<SaleManagement/>} />
        <Route path="/book" element={<BookSale/>} />
        <Route path="/jobupdates" element={<JobUpdates/>} />
        <Route path="/completedjobs" element={<CompletedJobs/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/jobstatus" element={<AwaitingJobs/>} />
        <Route path="*" element={<Navigate replace to={"/dashboard"} />} />
    </Routes> 
    </>
  )
}

export default RouteMember